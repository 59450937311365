import React from 'react';
import QuestionIcon from '../../assets/icons/topbar/help.svg';

const Help = () => (
  <div className="topbar-function">
    <a href="https://helpdesk.twcinnovations.com/" target="_blank" >
      <img src={QuestionIcon} alt="" />
    </a>
  </div>
);

export default Help;
