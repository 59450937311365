/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useRef, forwardRef, useEffect, useState
} from 'react';
import {
  useTable, useRowSelect, usePagination, useBlockLayout
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ActionBar from './ActionBar';
import BottomBar from './BottomBar';

// to render the checkboxes
const IndeterminateCheckbox = forwardRef(

  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const defaultColumn = {
  width: 280,
};

const Table = (props) => {
  const {
    columns, tableData, tableHeaders, checkedColumns, setCheckedColumns, searchInput, setSearchInput,
    globalSearch, tableName, activatePagination, category, setLeads, summary, activateTopActions, activateSearch, filterBy, setFilterBy, dealStages, viewAsInfo, activateFilters, activateSelection, activateColumns
  } = props;

  const [data, setData] = useState(tableData);

  const {
    getTableProps,
    getTableBodyProps,
    getToggleAllRowsSelectedProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setHiddenColumns,
    state: { pageSize, pageIndex },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 },
    defaultColumn,
  },
    usePagination,
    useRowSelect,
    (hooks) => {
      if (tableName === 'leads') {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            maxWidth: 95,
            Cell: ({ row }) => (
              <div style={{ paddingTop: '3px', paddingBottom: '7px' }}>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
            sticky: 'left',
          },
          ...columns,
        ]);
      }
    },
    useBlockLayout,
    useSticky);
  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  useEffect(() => {
    setHiddenColumns(
      columns.filter((column) => !column.isVisible).map((column) => column.accessor)
    );
  }, [setHiddenColumns, columns]);

  const dark = useSelector((state) => state.themeToggle.dark);
  return (
    <>
      <div className="action-bar">
        {
         (activateTopActions) ? (

           <>
             {
             (
               activateSelection ? (
                 <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="all-rows" />
               ) : ''
             )
           }

             <ActionBar
               checked={selectedFlatRows.length}
               available={tableData.length}
               tableHeaders={tableHeaders}
               checkedCols={checkedColumns}
               setCheckedColumns={setCheckedColumns}
               searchInput={searchInput}
               setSearchInput={setSearchInput}
               globalSearch={globalSearch}
               selectedRows={selectedFlatRows}
               setData={setData}
               category={category}
               setLeads={setLeads}
               filterBy={filterBy}
               setFilterBy={setFilterBy}
               filtersList={dealStages}
               viewAsInfo={viewAsInfo}
               activateSearch={activateSearch}
               activateFilters={activateFilters}
               activateSelection={activateSelection}
               activateColumns={activateColumns}
              />
           </>
         ) : ''
       }
      </div>
      <div className="react-table scroller scroll-width-thin scroll-direction" data-dark={dark}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className={column.id === 'email' ? 'status-header' : 'headers'}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => <td {...cell.getCellProps()} className={cell.column.id === 'email' ? 'status-rows' : 'rows'}>{cell.render('Cell')}</td>)}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {activatePagination === 1 ? (
        <BottomBar
          pageSize={pageSize}
          setPageSize={setPageSize}
          data={data}
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          selectedRows={selectedFlatRows}
          pageIndex={pageIndex}
          setLeads={setLeads}
          category={category}
          summary={summary}
        />
      ) : ('')}
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  tableHeaders: PropTypes.array,
  setVisibility: PropTypes.func,
  visibleHeader: PropTypes.func,
  invisibleHeader: PropTypes.func,
  checkedColumns: PropTypes.array,
  setCheckedColumns: PropTypes.func,
  searchInput: PropTypes.func,
  setSearchInput: PropTypes.func,
  globalSearch: PropTypes.string,
  tableName: PropTypes.string,
  category: PropTypes.string,
  setLeads: PropTypes.func,
  summary: PropTypes.array,
  viewAsInfo: PropTypes.object,
  filterBy: PropTypes.string,
  setFilterBy: PropTypes.func,
  dealStages: PropTypes.array,
  activateTopActions: PropTypes.bool,
  activatePagination: PropTypes.bool,
  activateSearch: PropTypes.bool,
  activateFilters: PropTypes.bool,
  activateSelection: PropTypes.bool,
  activateColumns: PropTypes.bool,
};
export default Table;
