import React from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

const getNotification = createSelector(
  (state) => state.notification,
  (notification) => notification,
);

const Notifications = () => {
  const { success, error, info } = useSelector(getNotification);
  return (
    <>
      {
        (success || error || info)
        && (
          <div className="global-notification-container global-notification-position">
            <div className={`global-notification ${success ? 'success' : (info ? 'info' : 'danger')}`}>
              <p className="global-notification-title">{success ? 'Notification!' : (info ? 'Information!' : 'WARNING!')}</p>
              <p className="global-notification-message">{(success || error || info)}</p>
            </div>
          </div>
        )
      }
    </>

  );
};

export default Notifications;
