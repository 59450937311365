import React from 'react'
import PropTypes from 'prop-types'
import parseDate from '../../../../utils/parseDateTime';

const FileActivities = (props) => {
    const { activity } = props;
    return (
        <div className="activity-item">
            <div className="activity-description">{activity.description}</div>
            <div className="activity-date">{parseDate(activity.created)}</div>
        </div>
    )
}

FileActivities.propTypes = {
    activity: PropTypes.array.isRequired,
}

export default FileActivities
