import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import countryList from 'react-select-country-list';
import { isEmail } from 'class-validator';
import axios from '../../../../utils/axios';
import useNotificationDispatcher from '../../../../hooks/useNotificationDispatcher';
import LabeledInput from '../../../../components/InputFields/LabeledInput';
import CountryCodeInput from '../../../../components/InputFields/CountryCodeInput';
import CountrySelect from '../../../../components/SearchSelect/CountrySelect';
import ConvertLeadToSaleValidate from '../../../../validation/ConvertLeadToSaleValidate';

const ConvertToSale = (props) => {
  const {
    closeModal, leadDetails, category, setLeads,
  } = props;
  const { id: leadId } = leadDetails;
  const [saleName, setSaleName] = useState('');
  const [salesNameError, setSalesNameError] = useState('');
  const [saleValue, setSaleValue] = useState(0);
  const [salesValueError, setSalesValueError] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerNameError, setCustomerNameError] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [contactNoError, setContactNoError] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState('');
  const [country, setCountry] = useState({ label: 'Select a Country...', value: '' });
  const [countryError, setCountryError] = useState('');
  const [textToNumber, setTextToNumber] = useState('');

  const notificationDispatch = useNotificationDispatcher();
  const countries = useMemo(() => countryList().getData(), []);

  const _setSaleName = (value) => {
    setSaleName(value);
    if (salesNameError) {
      setSalesNameError('');
    }
  };

  const _setSaleValue = (value) => {
    setSaleValue(value);
    if (salesValueError) {
      setSalesValueError('');
    }
  };

  const _setCustomerName = (value) => {
    setCustomerName(value);
    if (customerNameError) {
      setCustomerNameError('');
    }
  };

  const _setContactNo = (value) => {
    setContactNo(value);
    if (contactNoError) {
      setContactNoError('');
    }
  };

  const _setCity = (value) => {
    setCity(value);
    if (cityError) {
      setCityError('');
    }
  };

  const _setCountry = (value) => {
    setCountry(value);
    if (countryError) {
      setCountryError('');
    }
  };

  const convertIntoSale = async () => {
    try {
      if (!isEmail(emailAddress)) {
        notificationDispatch('error', 'Enter a correct Email!');
      }
      
      const data = {
        leadInfo: { leadId },
        salesInfo: { salesName: saleName, salesValue: saleValue },
        customerInfo: {
          name: customerName,
          address: addressLine1,
          addressLn2: addressLine2,
          city,
          country,
          email: emailAddress,
          contactNumber: contactNo,
          textToNumber,
        },
        currentSelectedCategory: category.value,
      };

      const dataValidation = ConvertLeadToSaleValidate(data);
      if (dataValidation.length === 0) {
        const convertRes = await axios.post('/leads/convert-to-sale', data);
        setLeads(convertRes.data.leads);
        notificationDispatch('success', 'Added Customer Successfully!');
        closeModal();
      } else {
        dataValidation.forEach((element) => {
          if (element.errorType.includes('sales_name')) {
            setSalesNameError(element.errorMessage);
          }
          if (element.errorType.includes('sales_value')) {
            setSalesValueError(element.errorMessage);
          }
          if (element.errorType.includes('customer_name')) {
            setCustomerNameError(element.errorMessage);
          }
          if (element.errorType.includes('customer_contact_no')) {
            setContactNoError(element.errorMessage);
          }
          if (element.errorType.includes('customer_city')) {
            setCityError(element.errorMessage);
          }
          if (element.errorType.includes('customer_country')) {
            setCountryError(element.errorMessage);
          }
        });
        notificationDispatch('error', 'Enter Correct Details!');
      }
    } catch (error) {
      console.log(error);
      notificationDispatch('error', 'Something went wrong!');
    }
  };

  useEffect(() => {
    const {
      contactNumber, email, city: cusCity, country: cusCountry,
    } = leadDetails;
    setContactNo(contactNumber);
    setEmailAddress(email);
    setCity(cusCity);
    countries.forEach((element) => {
      if (element.label === cusCountry) {
        setCountry(element);
      }
    });
  }, []);

  return (
    <>
      <form>
        <div className="seperator section-header">
          <div className="row mb-2" style={{ width: '100%' }}>
            <div className="col-6">
              <h1>Sales Information</h1>
            </div>
            <div className="col-6" style={{ padding: 0 }}>
              <button className="btn btn-crm-maroon float-right" type="button" onClick={convertIntoSale}>
                Submit
              </button>
              <button className="btn btn-outline-crm float-right mr-3" type="button" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <LabeledInput
              id="name"
              label="Name of the Sale"
              onChange={_setSaleName}
              value={saleName}
              errorMessage={salesNameError}
            />
          </div>
          <div className="col-md-4">
            <LabeledInput
              id="number"
              label="Sales Value"
              onChange={_setSaleValue}
              value={saleValue}
              errorMessage={salesValueError}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 seperator" style={{ padding: 0 }}>
            <h1>Customer Information</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <LabeledInput
              id="name"
              label="Name"
              onChange={_setCustomerName}
              value={customerName}
              errorMessage={customerNameError}
            />
          </div>
          <div className="col-md-4">
            <CountryCodeInput
              id="contactNumber"
              specialLabel="Contact Number"
              onChange={_setContactNo}
              value={contactNo}
              country="lk"
              errorMessage={contactNoError}
            />
          </div>
          <div className="col-md-4">
            <LabeledInput
              id="emailAddress"
              label="Email Address"
              onChange={setEmailAddress}
              value={emailAddress}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <LabeledInput
              id="addressLine1"
              label="Address Line 1"
              onChange={setAddressLine1}
              value={addressLine1}
            />
          </div>
          <div className="col-md-4">
            <LabeledInput
              id="addressLine2"
              label="Address Line 2"
              onChange={setAddressLine2}
              value={addressLine2}
            />
          </div>
          <div className="col-md-4">
            <LabeledInput
              id="city"
              label="City"
              onChange={_setCity}
              value={city}
              errorMessage={cityError}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <CountrySelect
              id="country"
              label="Country"
              onChange={_setCountry}
              defaultValue={country}
              useEventValue={false}
              errorMessage={countryError}
            />
          </div>
          <div className="col-md-4">
            <CountryCodeInput
              id="textToNumber"
              specialLabel="Text-to Number"
              onChange={setTextToNumber}
              value={textToNumber}
              country="lk"
            />
          </div>
        </div>
      </form>
    </>
  );
};

ConvertToSale.propTypes = {
  closeModal: PropTypes.func.isRequired,
  leadDetails: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  setLeads: PropTypes.func.isRequired,
};

export default ConvertToSale;
