import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from '../../../../utils/axios';
import useNotificationDispatcher from '../../../../hooks/useNotificationDispatcher';
import DeleteConfirmPopup from '../../../../pages/Leads/Main/Popups/ConfirmDelete';
import Modal from '../../Modal';

const Delete = (props) => {
    const { setLeads, selectedRows, category } = props;
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const notificationDispatch = useNotificationDispatcher();

    const openDeleteConfirmModal = () => {
    setDeleteConfirmModal(true);
  };

  const closeDeleteConfirmModal = () => {
    setDeleteConfirmModal(false);
  };
  const onDeleteBtnClick = async () => {
    for await (const element of selectedRows) {
      try {
        const updateData = { leadId: element.original.id, currentSelectedCategory: category.value };
        const res = await axios.put('leads/delete-selected-leads/', updateData);
        setLeads(res.data.leads);
        notificationDispatch('success', 'Lead Updated Successfully !');
        closeDeleteConfirmModal();
      } catch (error) {
        console.log(error);
        notificationDispatch('error', 'Something went wrong!');
      }
    }
};
return (
  <div className="btn-wrapper">
    <button type="button" className="delete-btn" onClick={openDeleteConfirmModal}>Delete</button>
    {
        deleteConfirmModal && (
          <Modal modalClose={closeDeleteConfirmModal} addClass="ConfirmModal">
            <DeleteConfirmPopup
              closeModal={closeDeleteConfirmModal}
              deleteLead={onDeleteBtnClick}
            />
          </Modal>
        )
      }
  </div>
);
};

Delete.propTypes = {
    setLeads: PropTypes.func.isRequired,
    selectedRows: PropTypes.array.isRequired,
    category: PropTypes.string.isRequired,
};
export default Delete;
