import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import Footer from '../Footer';

const Body = (props) => {
  const {
    children,
    title,
    breadCrumbs,
    buttonText,
    // icon,
    link,
    onClick,
    buttonText2,
    onClick2,
    link2,
    action,
    onAction,
    disabledFor,
    groupId
  } = props;
  const history = useHistory();

  const _onClick = () => {
    if (link) {
      history.push(link);
    } else {
      onClick();
    }
  };

  const _onClick2 = () => {
    if (link2) {
      history.push(link2);
    } else {
      onClick2();
    }
  };

  const crumbs = [];
  // eslint-disable-next-line array-callback-return
  breadCrumbs.map((crumb, index) => {
    // console.log(crumb);
    crumbs.push(<Link to={crumb.link}>{crumb.title}</Link>);
    if (index !== breadCrumbs.length - 1) {
      crumbs.push('-');
    }
  });

  return (
    <>
      <div className="scrollable-wrapper">
        <div className="content">
          <div className="content-header">
            <div className="content-label">
              <h1>{title}</h1>
              {/* <img src={icon} alt="icon" /> */}
              <span>
                {crumbs}
              </span>
            </div>
            <div className="content-function">
              {
                (action && !disabledFor.includes(groupId))
                && (
                  <button className="btn btn-crm-maroon mr-2" type="button" onClick={onAction}>
                    {action}
                  </button>
                )
              }
              {
                (buttonText2 && !disabledFor.includes(groupId))
                && (
                  <button className="btn btn-crm-maroon mr-2" type="button" onClick={_onClick2}>
                    {buttonText2}
                  </button>
                )
              }
              {
                (buttonText && !disabledFor.includes(groupId))
                && (
                  <button className="btn btn-crm-maroon" type="button" onClick={_onClick}>
                    {buttonText}
                  </button>
                )
              }
            </div>
          </div>
          <div className="content-body">
            {children}
          </div>
        </div>
      </div>

    </>
  );
};

Body.propTypes = {
  children: PropTypes.node.isRequired,
  // icon: PropTypes.node.isRequired,
  title: PropTypes.string,
  link: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  link2: PropTypes.string,
  buttonText2: PropTypes.string,
  onClick2: PropTypes.func,
  breadCrumbs: PropTypes.array,
  action: PropTypes.string,
  onAction: PropTypes.func,
  disabledFor: PropTypes.array,
  groupId: PropTypes.number,
};

Body.defaultProps = {
  breadCrumbs: [],
  disabledFor: [],
};

export default Body;
