import React, { useEffect, useState } from 'react'
import axios from '../../../../utils/axios';
import parseDate from '../../../../utils/parseDateTime';
import scrollToViewElement from '../../../../utils/scrollToViewElement';
import PropTypes from 'prop-types';
import FileInfo from './FileInfo';
import FileHistory from './FileHistory';
import FileActivity from './FileActivity';

const RightPane = (props) => {
    const { fileData, comments, fileId, setComments, setAddNew, setFileData, setDocs, history, setHistory, activities, setActivities, dataUpdates, setDataUpdates } = props;
    const [comment, setComment] = useState('');
    const [topInfoSection, setTopInfoSection] = useState(1);

    const { salesDealId } = fileData;
    useEffect(() => {
      scrollToViewElement('#endOfComments');
    });

    const fileInfoOnClick = () => {
      setTopInfoSection(1);
    }

    const fileHistoryOnClick = () => {
      setTopInfoSection(2);
    }

    const fileActivitiesOnClick = () => {
      setTopInfoSection(3);
    }

    const commentOnSubmit = async (e) => {
      e.preventDefault();
      const body = {
        commentText: comment,
        salesDealId,
      }

      try {
        const { data } = await axios.post(`/documents/comments/${fileId}`, body);
        setComments(data);
        console.log(e);
        setComment('');
        scrollToViewElement('#endOfComments');
        setDataUpdates(dataUpdates + 1);
      } catch (error) {
        console.log(error);
      }

    };

    const commentChangeHandler = (e) => {
      setComment(e.target.value);
    };

    let topInfoComponent = [];

    if (topInfoSection === 1) {
      topInfoComponent =  (<FileInfo fileId={fileId} fileData={fileData} setFileData={setFileData} setDocs={setDocs} setHistory={setHistory} setActivities={setActivities} dataUpdates={dataUpdates} setDataUpdates={setDataUpdates}/>);
    }   else if (topInfoSection === 2) {
      topInfoComponent = (<div className="file-history">{history.map((historyItem) => {
        return (
          <FileHistory history={historyItem} />
        );

      }) }</div>)
    } else {
      topInfoComponent = (<div className="file-activities">{activities.map((activity) => {
        return (
          <FileActivity activity={activity} />
        );

      }) }</div>)
    }

    let commentsComponent = [];
    if (comments) {
      commentsComponent = comments.map((comment) => {
        const { user, comment_text, created } = comment;
        return (
          <div className="single-comment">
            <div className="user">{user}</div>
            <div className="comment">{comment_text}</div>
            <div className="time">{parseDate(created)}</div>
          </div>
        );

      })
     
    }
    
    return (
           <>   
            <div className="toggle-buttons">
                  <button className={`toggle-button ${topInfoSection === 1 && `active`}`} onClick={fileInfoOnClick}>File Info</button>
                  <button className={`toggle-button ${topInfoSection === 2 && `active`}`} onClick={fileHistoryOnClick}>File History</button>
                  <button className={`toggle-button ${topInfoSection === 3 && `active`}`} onClick={fileActivitiesOnClick}>File Activities</button>
            </div>  
            
            <div className="top-info">
              {topInfoComponent}
            </div>
            <div className="comments-section">
              <div className="comments">{commentsComponent}
              <span id="endOfComments"></span></div>
                <form onSubmit={commentOnSubmit} className="comments-form">
                  <input type="text" className="input" onChange={commentChangeHandler} value={comment} />
                  <div className="submit-button">
                  <button type="submit" className="btn btn-crm-maroon spacing">Comment</button>
                  </div>
                </form>
            </div>
           </>
    )
}

RightPane.propTypes = {
    fileData: PropTypes.object.isRequired,
    comments: PropTypes.array.isRequired,
    setComments: PropTypes.func.isRequired,
    fileId: PropTypes.number.isRequired,
  };

export default RightPane
