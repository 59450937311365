import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LabeledInput from '../../../../components/InputFields/LabeledInput';
import QuestionTypeSelect from '../../../../components/SelectFields/QuestionTypeSelect';
import DateInput from '../../../../components/InputFields/DateInput';

import useNotificationDispatcher from '../../../../hooks/useNotificationDispatcher';

const SelectTypes = (props) => {
  const {
    setQuestionOptions, questionOptions, closeModal,
  } = props;

  const [optionTitle, setOptionTitle] = useState('');
  const [type, setType] = useState('');
  const [date, setDate] = useState();
  const [time, setTime] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const notificationDispatch = useNotificationDispatcher();

  const onSaveType = () => {
    if (!optionTitle || optionTitle === '') {
      notificationDispatch('error', 'Please Enter A Option Title!');
      return;
    }

    if (!type || type === '') {
      notificationDispatch('error', 'Please Enter A Valid Question Type');
      console.log('inside the type validation');
      return;
    }

    if (type.value === 'dateRange' && !startDate && !endDate) {
      notificationDispatch('error', 'Please Enter Start & End Dates!');
      return;
    }

    if (type.value === 'dateRange' && endDate <= startDate) {
      notificationDispatch('error', 'Please Enter A Valid End Date!');
      return;
    }

    if (type.value === 'timeRange' && !startTime && !endTime) {
      notificationDispatch('error', 'Please Enter A Time Slot!');
      return;
    }

    if (type.value === 'timeRange' && endTime <= startTime) {
      notificationDispatch('error', 'Please Enter A Valid End Time!');
      return;
    }

    const copyOfTypeFieldArr = [...questionOptions];
    copyOfTypeFieldArr.push({
      optionTitle, date, time, type, startTime, endTime, startDate, endDate
    });
    setQuestionOptions(copyOfTypeFieldArr);

    notificationDispatch('success', 'Added Option Successfully!');

    closeModal();
  };

  const selectOptionSection = (typ) => {
    setType(typ);
  };

  const _setDate = (e) => {
    setDate(e.target.value);
  };

  const _setStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const _setEndDate = (e) => {
    setEndDate(e.target.value);
  };

  return (
    <form>

      <div className="row float-right">
        <button type="button" onClick={onSaveType} className="btn btn-crm-maroon  ml-3 ">
          Submit
        </button>
        <button type="button" onClick={closeModal} className="btn btn-outline-crm  ml-3 ">
          Cancel
        </button>
      </div>

      <div className="row mb-3">
        <div className="col-md-3 mt-5">
          <LabeledInput
            id="title"
            label="Title"
            placeholder="Please Enter Question..."
            onChange={setOptionTitle}
            value={optionTitle}
          />
        </div>

        <div className="col-md-3 mt-5">
          <QuestionTypeSelect
            onSelectChange={selectOptionSection}
            value={type}

          />
        </div>

        <div className="col-md-3 mt-5 ">
          <DateInput
            value={date}
            label="Date"
            onChange={_setDate}
            id="date"
            name="date"
          />
        </div>

        <div className="col-md-3 mt-5">
          <LabeledInput
            type="time"
            value={time}
            label="Time"
            onChange={setTime}
            id="time"
            name="time"
          />
        </div>

      </div>

      <div className="row mt-10">

        <div className="col-md-3 ">
          <DateInput
            value={startDate}
            label="Date From"
            onChange={_setStartDate}
            id="slotstartDate"
            name="startDate"
          />
        </div>

        <div className="col-md-3 ">
          <DateInput
            value={endDate}
            label="Date To"
            onChange={_setEndDate}
            id="slotEndDate"
            name="startDate"
          />
        </div>

        <div className="col-md-3">
          <LabeledInput
            type="time"
            value={startTime}
            label="Starting Time"
            onChange={setStartTime}
            id="slotStartTime"
            name="slotStartTime"
          />
        </div>
        <div className="col-md-3">
          <LabeledInput
            type="time"
            value={endTime}
            label="End Time"
            onChange={setEndTime}
            id="slotEndTime"
            name="slotEndTime"
          />
        </div>

      </div>

      <div className="col-md-12" />
    </form>
  );
};

SelectTypes.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setQuestionOptions: PropTypes.func.isRequired,
  questionOptions: PropTypes.array.isRequired,

};

export default SelectTypes;
