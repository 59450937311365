import React from 'react';
import PropTypes from 'prop-types';

const Modal = (props) => {
  const {
    modalClose, addClass, children,
  } = props;
  return (
    <>
      <div className="modal show blur-backgroud" onClick={modalClose} />
      <div className={`modal-dialog ${addClass && addClass}`}>
        <div className="modal-content">
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>

    </>
  );
};

Modal.propTypes = {
  modalClose: PropTypes.func,
  addClass: PropTypes.string,
  children: PropTypes.node,
};

export default Modal;
