/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from '../../../utils/axios';
import Body from '../../../layouts/Body';
import Loader from '../../../components/Loaders';
import LeadsTable from './LeadsTable/LeadsTable';
import Actions from './Actions';
import SectionHeader from '../../../components/Common/SectionHeader';
import ScrollModal from '../../../components/Common/ScrollModal';

const Leads = () => {
  const [leads, setLeads] = useState([]);
  const [optionalColumns, setOptionalColumns] = useState([]);
  const [pageLoader, setPageLoader] = useState(false);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  // const [showMeetingSchedule, setShowMeetingSchedule] = useState(true);
  const [headers, setHeaders] = useState([]);
  const [summary, setSummary] = useState([]);
  const selectedOptimizeFilter = { label: 'All', value: 1 };

  const { leadId } = useParams();
  const location = useLocation();

  const getLeads = async () => {
    try {
      let res = { data: [] };

      if (location.pathname.includes('filter-by')) {
        res = await axios.get(`/leads/filter-by/${leadId}`);
      } else {
        res = await axios.get(`/leads/optimize-by/${selectedOptimizeFilter.value}`);
      }
      setLeads(res.data.leads);
      setFilteredLeads(res.data.leads);

      // setShowMeetingSchedule(res.data.showLeadMeetingSchedule);
      setPageLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptional = async () => {
    let opt = { data: [] };
    try {
      opt = await axios.get('/leads/optional-columns');
    } catch (error) {
      console.log(error);
    }
    setOptionalColumns(opt.data.col);
  };

  const getTableHeaders = async () => {
    if (headers.length === 0) {
      let res = { data: [] };
      try {
        res = await axios.get('/leads/table-headers');
        setHeaders(res.data.headers.map((obj) => ({ ...obj, checked: false })));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getSummary = async () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    today = `${yyyy}-${mm}-${dd}`;
    try {
      const res = await axios.get(`leads/${today}/get-summary-by-category/${selectedOptimizeFilter.value}`);
      setSummary(res.data.summary);
    } catch (error) {
      console.log(error);
    }
  };

  const globalSearch = (key) => {
    if (key) {
      const filteredData = leads.filter((value) => (
        value.leadTitle.toLowerCase().includes(key.toLowerCase())
      ));
      setFilteredLeads(filteredData);
    } else {
      setFilteredLeads(leads);
    }
  };

  const _setSearchInput = (input) => {
    setSearchInput(input);
    globalSearch(input);
  };

  useEffect(() => {
    getLeads();
    getTableHeaders();
    getOptional();
    getSummary();
  }, []);
  return (
    <Body>
      <ScrollModal />
      {
        !pageLoader ? (
          <div className="leads-main">
            <Actions
              selectedFilter={selectedOptimizeFilter}
              // onFilterSelected={setSelectedOptimizeFilter}
              setLeads={setFilteredLeads}
            />
            <SectionHeader
              seperator={false}
              globalSearch
              searchInput={searchInput}
              setSearchInput={_setSearchInput} />
            <section>
              <LeadsTable
                leadData={filteredLeads}
                category={selectedOptimizeFilter}
                setLeads={setFilteredLeads}
                options={optionalColumns}
                tableHeaders={headers}
                searchInput={searchInput}
                setSearchInput={_setSearchInput}
                globalSearch={globalSearch}
                summary={summary}
                // showMeetingSchedule={showMeetingSchedule}
              />
            </section>

          </div>
        ) : (
          <Loader />
        )
      }
    </Body>
  );
};

export default Leads;
