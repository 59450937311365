import React, { } from 'react';
import PropTypes from 'prop-types';
import SearchSelect from '../InputFields/SearchSelect';
// import axios from '../../utils/axios';

const QuestionTypeSelect = (props) => {
  const { onSelectChange, value } = props;
  const QuestionTypes = [
    { label: 'Text', value: 'text' },
    { label: 'Select', value: 'select' },
    { label: 'Date', value: 'date' },
    { label: 'Date Range', value: 'dateRange' },
    { label: 'Time', value: 'time' },
    { label: 'Time Range', value: 'timeRange' },
    { label: 'Number', value: 'number' }
  ];
  console.log(value);

  return (
    <>
      <SearchSelect
        options={QuestionTypes}
        placeholder="Select Type..."
        onChange={onSelectChange}
        name="questionTypes"
        label="Type"
        useEventValue={false}
        controlled
        value={value}

      />
    </>
  );
};

QuestionTypeSelect.propTypes = {
  onSelectChange: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default QuestionTypeSelect;
