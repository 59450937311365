/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import filterIcon from '../../../../assets/icons/filters.svg';

const Filters = (props) => {
    const {
 filterBy, setFilterBy, viewAsInfo, filtersList 
} = props;
    const [filterDrop, setfilterDrop] = useState(false);

    const filterBySelected = (item) => {
        setfilterDrop(!filterDrop);
        setFilterBy(item, viewAsInfo);
      };
      const filterDropdown = () => {
        setfilterDrop(!filterDrop);
      };

    const filterDropElement = (
      <>
        <div
          onClick={() => setfilterDrop(false)}
          className="backdrop global-modal-closer" />
        <div className="drop-down card" style={{ zIndex: 20 }}>
          <ul>
            {filtersList.map(
                (item, i) => (
                  <li
                    key={i}
                    onClick={() => filterBySelected(item)}>
                    <>
                      {item.label}
                    </>
                  </li>
                )
              )}
          </ul>
        </div>
      </>
      );
  return (
    <div>
      
      <div style={{ paddingLeft: '860px' }}>
        <label>Filters</label>
        <span onClick={filterDropdown}>
          <img src={filterIcon} alt="filter" />
          {
              filterDrop && (
                filterDropElement
              )
            }
        </span>
      </div>
     
    </div>
  );
};

Filters.propTypes = {
    filterBy: PropTypes.string.isRequired,
    setFilterBy: PropTypes.func.isRequired,
    viewAsInfo: PropTypes.object.isRequired,
    filtersList: PropTypes.array.isRequired,
};
export default Filters;
