import React, { memo } from 'react';
import PropTypes from 'prop-types';

const DateInput = memo((props) => {
  const { 
      name, value, label, error, info, required, onChange, id, disabled,
  } = props;

  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        type="date"
        name={name}
        className={`form-controlx form-control-sm ${error ? 'is-invalid' : ''}`}
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        required={required}
      />
      {info && <small className="form-text text-muted">{info}</small>}
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
});

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  info: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DateInput;
