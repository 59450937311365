import { SET_SALES_DEAL_DETAILS, CLEAR_SALES_DEAL_DETAILS } from '../actions/types';

const initialState = {
  customer: {},
  origin: {}
};

// SET SALES DEAL INFO
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SALES_DEAL_DETAILS:
      return action.payload;
    case CLEAR_SALES_DEAL_DETAILS:
      return {
        customer: {},
        origin: {},
      };
    default:
      return state;
  }
};
