import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import axios from '../../../../utils/axios';
import useNotificationDispatcher from '../../../../hooks/useNotificationDispatcher';

import LeadStatusList from '../DropDowns/LeadStatusList';
import editIcon from '../../../../assets/icons/pencil.svg';

const LeadStatusCell = (props) => {
  const {
    rowData, clickedRow, setLeads, category
  } = props;
  const updateStatusInput = useRef(null);
  const [statusUpdateDropDown, setStatusUpdateDropDown] = useState(false);
  const [edit, setEdit] = useState(false);
  const [enableTextEdit, setEnableTextEdit] = useState(false);

  const notificationDispatch = useNotificationDispatcher();

  const _onStatusUpdateClick = () => {
    if (rowData.id === clickedRow) {
      setStatusUpdateDropDown(!statusUpdateDropDown);
    }
  };

  const updateLeadStatus = async (item) => {
    try {
      const updateData = { leadId: rowData.id, statusId: item.value, currentSelectedCategory: category.value };
      const updateRes = await axios.put('/leads/update-status', updateData);
      setLeads(updateRes.data.leads);
      notificationDispatch('success', 'Lead Updated Successfully!');
    } catch (error) {
      console.log(error);
      notificationDispatch('error', 'Something went wrong!');
    }
  };

  const _setFilterItem = (item) => {
    updateLeadStatus(item);
  };

const showEdit = () => {
    setEdit(true);
};
const hideEdit = () => {
    setEdit(false);
};

const onEditClick = () => {
    setEnableTextEdit(true);
    updateStatusInput.current.focus();
    _onStatusUpdateClick();
    setEdit(false);
};

  return (
    <div className="actions cell-dropdown" style={{ flex: 1 }}>
      <div className="cell-dropdown-align cell-container">
        <div style={{ display: 'grid', gridTemplateColumns: '5fr 1fr', columnGap: '5px' }}>
          <div onMouseEnter={showEdit} onMouseLeave={hideEdit}>
            <input
              value={rowData.status}
              ref={updateStatusInput}
              disabled
              className={enableTextEdit ? 'editable-texts' : 'normal-texts'}
              style={{ cursor: 'context-menu' }} 
                />
          </div>
          <div onMouseEnter={showEdit} onMouseLeave={hideEdit} onMouseOver={showEdit} onFocus={showEdit} onBlur={hideEdit} onClick={onEditClick}> 
            {edit 
            ? <img src={editIcon} alt="" /> : ''}
          </div>
        </div>
      </div>
      {
        statusUpdateDropDown && (
          <LeadStatusList setFilteItem={_setFilterItem} />
        )
      }
    </div>
    
  );
};

LeadStatusCell.propTypes = {
  setLeads: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  clickedRow: PropTypes.number.isRequired,
  category: PropTypes.object.isRequired,
};

export default LeadStatusCell;
