import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import axios from '../../../utils/axios';
import useNotificationDispatcher from '../../../hooks/useNotificationDispatcher';
import CreateFormValidate from '../../../validation/CreateFormValidate';

import Body from '../../../layouts/Body';
import SectionHeader from '../../../components/Common/SectionHeader';
import LabeledInput from '../../../components/InputFields/LabeledInput';
import TextAreaInput from '../../../components/InputFields/TextAreaInput';
import NewQuestionField from './AddNew/QuestionField';
import AddNewQuestion from './AddNew/AddNewQuestion';

const StartForm = () => {
  const [formName, setFormName] = useState('');
  const [formDiscription, setFormDiscription] = useState();
  const [addNewQuestionExpand, setAddNewQuestionExpand] = useState(false);
  const [questions, setQuestions] = useState([]);

  const notificationDispatch = useNotificationDispatcher();
  const history = useHistory();

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        formName, formDiscription, questions,
      };
      const createFormValidation = CreateFormValidate(data);

      if (!createFormValidation.status) {
        console.log(data);
        await axios.post('/new-forms', data);
        notificationDispatch('success', 'Added Form Successfully!');
        history.push('/forms');
      } else {
        notificationDispatch('error', createFormValidation.errorMessage);
      }
    } catch (error) {
      console.log(error);
      notificationDispatch('error', 'Something went wrong!');
    }
  };

  const toggleAddNewQuestionSection = () => {
    setAddNewQuestionExpand(!addNewQuestionExpand);
  };

  return (
    <Body>
      <section>
        <SectionHeader
          title="New Form"
        />
        <form onSubmit={onSubmit}>
          <div className="col-md-6">
            <LabeledInput
              id="formName"
              label="Form Name"
              placeholder="e.g. Form 1"
              onChange={setFormName}
              value={formName}
            />
          </div>
          <div className="col-md-12 ">
            <TextAreaInput
              id="formDiscription"
              label="Form Description"
              placeholder="Please enter description..."
              onChange={setFormDiscription}
              value={formDiscription}
            />

          </div>

          <h2 className="my-4">Questions</h2>

          <div className="align-items-horizontal" style={{ flexDirection: 'column' }}>
            {questions.length > 0
              && questions.map((item, i) => (
                <NewQuestionField
                  question={item}
                  itemNumber={i}
                  questions={questions}
                  setQuestions={setQuestions}
                />
              ))}
          </div>

          <span className="expand-by-text">
            <u
              onClick={toggleAddNewQuestionSection}
            >
              <u className="mt-5">Add New Questions</u>

            </u>
          </span>

          {
            addNewQuestionExpand && (
              <>

                <AddNewQuestion
                  setQuestions={setQuestions}
                  questions={questions}
                />

              </>
            )
          }

          <div className="mt-4">
            {/* <button type="button" className="btn btn-outline-crm ml-4 mx-4" onClick={onPreviewForms}>
              Preview Form
            </button> */}

            <button type="submit" className="btn btn-crm-maroon mx-4 ">Save</button>

          </div>
          <div className="col-md-6 " />

        </form>
      </section>
    </Body>
  );
};

export default StartForm;
