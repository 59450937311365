import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import axios from '../../../../utils/axios';
import useNotificationDispatcher from '../../../../hooks/useNotificationDispatcher';

import LeadOriginList from '../DropDowns/LeadOriginList';
import editIcon from '../../../../assets/icons/pencil.svg';

const Origin = (props) => {
    const { 
        rowData, clickedRow, setLeads, category
     } = props;
     const updateInput = useRef(null);
     const [originUpdateDrop, setOriginUpdateDrop] = useState(false);
     const [edit, setEdit] = useState(false);
     const [enableTextEdit, setEnableTextEdit] = useState(false);
     const notificationDispatch = useNotificationDispatcher();

     const _onOriginUpdateClick = () => {
         if (rowData.id === clickedRow) {
             setOriginUpdateDrop(!originUpdateDrop);
         }
     };

     const updateOrigin = async (item) => {
         try {
             const updateData = { leadId: rowData.id, newOriginId: item.value, currentSelectedCategory: category.value };
             console.log(updateData);
             const updateRes = await axios.put('/leads/update-origin', updateData);
             setLeads(updateRes.data.leads);
             console.log(updateRes.data.leads);
             setOriginUpdateDrop(false);
             notificationDispatch('success', 'Lead Updated Successfully!');
         } catch (error) {
             console.log(error);
             notificationDispatch('error', 'Something went wrong!');
         }
     };

     const _setFilterItem = (item) => {
         updateOrigin(item);
     };
     const showEdit = () => {
        setEdit(true);
    };
    const hideEdit = () => {
        setEdit(false);
    };

    const onEditClick = () => {
        setEnableTextEdit(true);
        updateInput.current.focus();
        _onOriginUpdateClick();
        setEdit(false);
    };

    return (
      <div className="actions cell-dropdown" style={{ flex: 1 }}>
        <div className="cell-dropdown-align cell-container">  
          <div style={{ display: 'grid', gridTemplateColumns: '5fr 1fr', columnGap: '5px' }}>
            <div onMouseEnter={showEdit} onMouseLeave={hideEdit}>
              <input
                value={rowData.origin}
                ref={updateInput}
                disabled
                className={enableTextEdit ? 'editable-texts' : 'normal-texts'}
                style={{ cursor: 'context-menu' }} 
                />
            </div>
            <div onMouseEnter={showEdit} onMouseLeave={hideEdit} onMouseOver={showEdit} onFocus={showEdit} onBlur={hideEdit} onClick={onEditClick}> 
              {edit 
            ? <img src={editIcon} alt="" /> : ''}
            </div>
          </div>
        </div>
        {
              originUpdateDrop && (
              <LeadOriginList setFilteItem={_setFilterItem} />
              )
          }
      </div>
    );
};

Origin.propTypes = {
    setLeads: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
    clickedRow: PropTypes.number.isRequired,
    category: PropTypes.object.isRequired,
};

export default Origin;
