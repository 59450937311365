import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import axios from '../../../../utils/axios';
import useNotificationDispatcher from '../../../../hooks/useNotificationDispatcher';

import LabeledInput from '../../../../components/InputFields/LabeledInput';
import Checkbox from '@material-ui/core/Checkbox';


const GeneratePublicLink = (props) => {
  const {
    dataUpdates, setDataUpdates, close, fileId, setActivities, salesDealId, setFileData,
  } = props;
  const [password, setPassword] = useState(null);
  const [passwordRequired, setpasswordRequired] = useState(false)

  const notificationDispatch = useNotificationDispatcher();

  const onGenerate = async () => {
    const data = {
      typeId: 1,
      resourceId: fileId,
      isProtected: passwordRequired,
      password,
      salesDealId
    }
    try {
      const res = await axios.post(`/public-link/generate`, data);
      notificationDispatch('success', 'Public Link Generated successfully!');
      setActivities(res.data.documentActivities);
      setFileData(res.data.documentData[0]);
      setDataUpdates(dataUpdates + 1);
      close();
    } catch (error) {
      console.log(error);
      notificationDispatch('error', error?.response?.data?.message);
    }
    close();
  };

  const onCancel = () => {
    close();
  };

  const onPasswordRequiredClick = (e) => {
    setpasswordRequired(e.target.checked);
    setPassword(null);
  };

  return (
    <>
       
    <div className="upload-file-zone"> 
    <Checkbox
      checked={passwordRequired}
      color="primary"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
      style={{ height: '35px', width: '35px' }}
      onChange={onPasswordRequiredClick}
    />
    <span>Password Protect the Link</span> 
    {passwordRequired &&
       <LabeledInput
       id="password"
       type="password"
       label="Enter Password"
       onChange={setPassword}
       value={password}
       required={false}
     />  }
    </div>
    <div className="action-buttons">
        <button type="submit" className="btn btn-crm-maroon float-right ml-3" onClick={onGenerate}>Generate</button>
        <button type="button" className="btn btn-crm-maroon float-right ml-3" onClick={onCancel}>Cancel</button>
    </div>
     
    </>
  );
};

GeneratePublicLink.propTypes = {
  salesDealId: PropTypes.number.isRequired,
  dataUpdates: PropTypes.number.isRequired,
};

export default GeneratePublicLink;
