// USER
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const CLEAR_SUCCESS_MESSAGE = 'CLEAR_SUCCESS_MESSAGE';
export const SET_INFO_MESSAGE = 'SET_INFO_MESSAGE';
export const CLEAR_INFO_MESSAGE = 'CLEAR_INFO_MESSAGE';

export const SET_SALES_DEAL_DETAILS = 'SET_SALES_DEAL_DETAILS';
export const CLEAR_SALES_DEAL_DETAILS = 'CLEAR_SALES_DEAL_DETAILS';

export const SELECT_CALENDAR_EVENT = 'SELECT_CALENDAR_EVENT';
export const CLEAR_CALENDAR_EVENT = 'CLEAR_CALENDAR_EVENT';
export const ADD_TODAYS_EVENTS = 'ADD_TODAYS_EVENTS';
export const CLEAR_TODAYS_EVENTS = 'CLEAR_TODAYS_EVENTS';

export const ADD_SCHEDULED_EVENTS = 'ADD_SCHEDULED_EVENTS';
export const CLEAR_SCHEDULED_EVENTS = 'CLEAR_SCHEDULED_EVENTS';

// export const darkTheme = 'DARK_THEME';
// TODO Make below actions look consistent with above
export const darkTheme = () => ({
  type: 'DARK_THEME'
});

export const darkThemeOff = () => ({
  type: 'DARK_THEME_OFF'
});

export const scrollUp = () => ({
  type: 'SCROLL_UP'
});

export const scrollDown = () => ({
  type: 'SCROLL_DOWN'
});

export const scrollHide = () => ({
  type: 'SCROLL_HIDE'
});
