import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';

const NewQuestionField = (props) => {
  const {
    question, itemNumber, questions, setQuestions
  } = props;
  const {
    title,
  } = question;

  const [questionCompulsory, setQuestionCompulsory] = useState(false);

  const onQuestionCompulsoryClick = (e) => {
    setQuestionCompulsory(e.target.checked);
    const copyOfQuestions = [...questions];
    copyOfQuestions[itemNumber].questionCompulsory = e.target.checked;
    setQuestions(copyOfQuestions);
  };

  return (
    <div className="align-items-horizontal col-12 " style={{ flex: 1 }}>
      <span className="subtask-title mt-2">
        <h1>{title}</h1>

      </span>

      <div className="ml-5 isCompulsory ">
        <div className="check-sub-task ">
          <Checkbox
            checked={questionCompulsory}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            style={{ height: '35px', width: '35px' }}
            onChange={onQuestionCompulsoryClick}
          />
        </div>
        <h1>Compulsory</h1>
      </div>
    </div>
  );
};

NewQuestionField.propTypes = {
  question: PropTypes.object.isRequired,
  itemNumber: PropTypes.number.isRequired,
  setQuestions: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,

};

export default NewQuestionField;
