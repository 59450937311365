import React from 'react';
import PropTypes from 'prop-types';
import searchIcon from '../../../../assets/icons/search.svg';

const TableSearch = (props) => {
    const { searchInput, setSearchInput } = props;
    
    const _setSearchInput = (e) => {
        setSearchInput(e.target.value);
      };
  return (
     
    <div className="action-bar-left">
      <div>
        <input
          type="text"
          id="search"
          onChange={_setSearchInput}
          value={searchInput}
          placeholder="Type to search this table"
          className="labled-input"
          />

        <img src={searchIcon} alt="search" className="seaerch-img" />

      </div>
    </div>
      
  ); 
};
TableSearch.propTypes = {
    searchInput: PropTypes.string.isRequired,
    setSearchInput: PropTypes.func.isRequired,
};

export default TableSearch;
