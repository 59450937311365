/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../../utils/axios';
import useNotificationDispatcher from '../../../hooks/useNotificationDispatcher';
import { SET_SALES_DEAL_DETAILS, darkTheme, scrollUp } from '../../../actions/types';
import Modal from '../Modal';
import ConvertToSale from '../../../pages/Leads/Main/Popups/ConvertToSale';

const BottomBar = (props) => {
  const {
    pageSize, setPageSize, data, gotoPage, previousPage, canPreviousPage, nextPage, canNextPage,
    selectedRows, pageIndex, category, setLeads, summary
  } = props;

  const [todaysLeadsList, setTodaysleadsList] = useState([]);
  const [freshLeads, setFreshLeads] = useState([]);
  const [selected, setSelected] = useState(false);
  const [firstpageNum, setFirstPageNum] = useState(1);
  const [lastPageNum, setLastPageNum] = useState(2);
  const [convertToSalesModal, setConvertToSalesModal] = useState(false);
  const unattended = 'Fresh';

  const dispatch = useDispatch();
  const history = useHistory();
  const notificationDispatch = useNotificationDispatcher();

  const closeConvertToSaleModal = () => {
    setConvertToSalesModal(false);
  };

  const openConvertToSaleModal = () => {
    if (selectedRows.length === 1) {
      setConvertToSalesModal(true);
    } else {
      notificationDispatch('error', 'Cannot Convert Multiple Leads At Once. Please Choose One!');
    }
  };

  // function to convert to deal (OLD)
  const onConvertDealClick = () => {
    const {
      id, origin, leadTitle, country, contactNumber, email,
    } = selectedRows[0].original;
    const payloadData = {
      lead: { id },
      origin: { type: origin },
      customerInfo: {
        leadTitle, country, contactNumber, email
      },
    };
    dispatch({ type: SET_SALES_DEAL_DETAILS, payload: payloadData });
    history.push('/start-deal');
  };

  // function to render page buttons
  function pageButtons() {
    const buttonList = [null];

    const num = ((data.length) % pageSize);
    let num2 = ((data.length) / pageSize);
    if (num < 1) {
      const addNum = 1 - num;
      num2 += addNum;
    }

    for (let index = 0; index <= num2; index++) {
      buttonList.push(<button type="button" onClick={() => gotoPage(index)} className="pagination-btns active">{index + 1}</button>);
      // buttonList.push(index + 1);
      // onClick={() => gotoPage(index)}
    }

    return buttonList;
  }

  const content = (
    <>

      <div className="pagination" style={{ margin: 'auto 0' }}>
        <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage} className="pagination-btns">
          {'<'}
        </button>
        {pageButtons()}
        <button type="button" onClick={() => nextPage()} disabled={!canNextPage} className="pagination-btns">
          {'>'}
        </button>

      </div>
      <div>
        <select
          className="row-control"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((p) => (
            <option key={p} value={p}>
              Rows per page {p}
            </option>
          ))}
        </select>
      </div>
    </>
  );
  // useEffect(() => {
  //   changePageButtons();
  // }, [pageIndex]);
  useEffect(() => {
    if (selectedRows.length > 0) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedRows]);

  const colNum = summary.length + 2;

  const handleClick = () => {
    dispatch(darkTheme());
    dispatch(scrollUp());
  };

  const dark = useSelector((state) => state.themeToggle.dark);
  return (
    <div className="bottom-actions" data-dark={dark}>
      {/* <div>
            <legend>Scroll for more &gt; </legend>
          </div> */}
      {!selected
        ? (
          <div style={{ display: 'grid', gridTemplateColumns: `repeat(${colNum}, 1fr)` }}>
            {
              summary && (
                summary.map((value) => (
                  <div className="counts-div" onClick={handleClick}>
                    <div className="label-span">{value.name}</div>
                    <div className="count-span">{value.count}</div>
                  </div>
                ))
              )
            }

            {content}
          </div>
        )
        : (
          <div className="when-selected">
            <div>
              <button type="button" className="deal-click" onClick={onConvertDealClick}>Convert to Deal &gt;</button>
            </div>
            <div>
              <button type="button" className="sale-click" onClick={openConvertToSaleModal}>Convert to Sale</button>
            </div>
            {content}
          </div>
        )}

      {
        (convertToSalesModal && selectedRows.length) && (
          <Modal modalClose={closeConvertToSaleModal} addClass="ConvertToSale">
            <ConvertToSale
              closeModal={closeConvertToSaleModal}
              leadDetails={selectedRows[0].original}
              category={category}
              setLeads={setLeads}
            />
          </Modal>
        )
      }

    </div>

  );
};

BottomBar.propTypes = {
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  gotoPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  canPreviousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  pageIndex: PropTypes.string.isRequired,
  category: PropTypes.object.isRequired,
  setLeads: PropTypes.func.isRequired,
  summary: PropTypes.array.isRequired,
};
export default BottomBar;
