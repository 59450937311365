import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// import LabeledInput from '../../InputFields/LabeledInput';

const SectionHeader = (props) => {
  const {
    title,
    seperator,
    link,
    onClick,
    buttonText,
    link2,
    onClick2,
    buttonText2,
    action,
    onAction,
    stat,
    // globalSearch,
    // searchInput,
    // setSearchInput,
    subSectionText,
  } = props;

  const history = useHistory();

  const _onClick = (e) => {
    e.preventDefault();
    if (link) {
      history.push(link);
    } else {
      onClick();
    }
  };

  const _onClick2 = (e) => {
    e.preventDefault();
    if (link2) {
      history.push(link2);
    } else {
      onClick2();
    }
  };

  return (
    <div>
      <span className={`section-header ${(seperator && !subSectionText) && 'seperator'}`}>
        <h4>{title}</h4>
        <div className="button-containers">
          {
            (buttonText2)
            && (
              <button className="btn btn-outline-crm" type="button" onClick={_onClick2}>
                {buttonText2}
              </button>
            )
          }
          {
            (buttonText)
            && (
              <button className="btn btn-crm-maroon" type="button" onClick={_onClick}>
                {buttonText}
              </button>
            )
          }
          {
            (action)
            && (
              <button className="btn btn-crm-maroon btn-save" type="button" onClick={onAction}>
                {action}
              </button>
            )
          }
        </div>
        <div>
          {/* {
            (globalSearch)
            && (
              <LabeledInput
                id="search"
                onChange={setSearchInput}
                value={searchInput}
                placeholder="Search"
              />
            )
          } */}
        </div>
        {
          stat
          && (
            <div className="col-3 text-center">
              <h4>{stat}</h4>
            </div>
          )
        }
      </span>
      {
        subSectionText && (
          <div>
            <span className="section-header seperator">
              <h1>{subSectionText}</h1>
            </span>
          </div>
        )
      }
    </div>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  seperator: PropTypes.bool,
  link: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  link2: PropTypes.string,
  buttonText2: PropTypes.string,
  onClick2: PropTypes.func,
  action: PropTypes.string,
  onAction: PropTypes.func,
  stat: PropTypes.string,
  // globalSearch: PropTypes.string,
  // searchInput: PropTypes.string,
  // setSearchInput: PropTypes.func,
  subSectionText: PropTypes.string,
};

SectionHeader.defaultProps = {
  seperator: true,
};

export default React.memo(SectionHeader);
