import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import axios from '../../../utils/axios';

// import DownArrowIcon from '../../../assets/icons/chevron-down.svg';
import PlusIcon from '../../../assets/icons/plus.svg';
// import OptimizeForDropDownList from './DropDowns/LeadStatusList';
// import OptimizeFilter from './DropDowns/OptimizeFilter';
import FormsList from './DropDowns/FormsList';

const Actions = (props) => {
  const { setLeads } = props;
  // const [optimizeForDropDown, setOptimizeForDropDown] = useState(false);
  const [viewColdLeads, setViewColdLeads] = useState(false);
  const [formSelector, setFormSelector] = useState(false);

  const history = useHistory();

  // const onOptimizeForeClick = () => {
  //   setOptimizeForDropDown(!optimizeForDropDown);
  // };

  // const _onFilterSelect = (item) => {
  //   onFilterSelected(item);
  //   setOptimizeForDropDown(false);
  // };
  const onColdLeadsClick = async () => {
    try {
      let res = null;
      if (!viewColdLeads) {
        res = await axios.get('/leads/optimize-by/cold');
      } else {
        res = await axios.get('/leads/optimize-by/1');
      }
      setLeads(res.data.leads);
      setViewColdLeads(!viewColdLeads);
    } catch (error) {
      console.log(error);
    }
  };

  let viewColdLeadsBtnText = '';
  if (!viewColdLeads) {
    viewColdLeadsBtnText = 'View Cold Leads';
  } else {
    viewColdLeadsBtnText = 'View All';
  }

  const onStartLeadClick = () => {
    setFormSelector(true);
  };

  const onFormItemSelect = (selectedItem) => {
    setFormSelector(false);
    history.push(`/leads/start-lead/${selectedItem.value}`);
  };

  const onStartForms = () => {
    history.push('/forms');
  };
 const scroll = useSelector((state) => state.modalScroll.scroll);
  return (
    <>
      <div className="align-items-horizontal actions">
        <div className="left-container">
          <label className="main-leads-label" data-scroll={scroll}>Leads</label>
        </div>
        {/* <h1 className="mr-2">Optimize for </h1>
        <div className="optimze-items">
          <button type="button" className="btn btn-crm-white status-update-btn" onClick={onOptimizeForeClick}>
            {selectedFilter.label}
            <img className="float-right" src={DownArrowIcon} alt="" />
          </button>
          {
            optimizeForDropDown && (
              <OptimizeFilter setFilteItem={_onFilterSelect} close={() => setOptimizeForDropDown(false)} />
            )
          }
        </div> */}
        {/* <button type="button" className="btn btn-outline-crm ml-4">
          Start Newsletter Campaign
        </button> */}

        {/* <button type="button" className="btn btn-outline-crm ml-4" onClick={onStartForms}>
          Start Form Campaign
        </button> */}

        <div className="right-container">
          <div className="form-category-items">
            <button type="button" className=" btn-crm-maroon float-right ml-20 btn-add-with-img start-lead-btn" onClick={onStartLeadClick}>
              Add New Lead
              <img src={PlusIcon} alt="" />
            </button>
            {
              formSelector && (
                <FormsList setFilteItem={onFormItemSelect} close={() => setFormSelector(false)} />
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

Actions.propTypes = {
  // selectedFilter: PropTypes.object.isRequired,
  // onFilterSelected: PropTypes.func.isRequired,
  setLeads: PropTypes.func.isRequired,
};

export default Actions;
