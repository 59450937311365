const validate = (data) => {
  const { salesInfo, customerInfo } = data;
  const { salesName, salesValue } = salesInfo;
  const {
    name: cusName, contactNumber, city, country
  } = customerInfo;

  const errorList = [];
  if (!salesName) {
    errorList.push({ errorType: 'sales_name', errorMessage: 'Enter a valid sales name!' });
  }

  if (!salesValue) {
    errorList.push({ errorType: 'sales_value', errorMessage: 'Enter a valid sales value!' });
  }

  if (!cusName) {
    errorList.push({ errorType: 'customer_name', errorMessage: 'Enter a valid customer name!' });
  }

  if (!contactNumber) {
    errorList.push({ errorType: 'customer_contact_no', errorMessage: 'Enter a valid contact number!' });
  }

  if (!city) {
    errorList.push({ errorType: 'customer_city', errorMessage: 'Enter a valid city!' });
  }

  if (!country.value) {
    errorList.push({ errorType: 'customer_country', errorMessage: 'Select a Country!' });
  }

  return errorList;
};

export default validate;
