import React from 'react';
import Phone from './a.png';

const Features = () => (
  <div className="features">
    {/* <div className="content">
      <h1>All New CRM App</h1>
      <p>Lorem.Totam unde sapiente velit nulla! ipsum dolor sit amet cum id veniam Recusandae.</p>
    </div> */}
    <div className="image">
      <div className="img-wrapper">
        <img src={Phone} alt="" />
      </div>
    </div>
  </div>
);

export default Features;
