import React, { memo } from 'react';
import PropTypes from 'prop-types';

const DocumentUpload = memo((props) => {
  const {
    onChange,
    label,
    onDrop,
  } = props;

  const _onChange = (e) => {
    onChange(e.target.files);
    // console.log(e.dataTransfer.files);
  };
  const _onDrop = (e) => {
    onDrop(e.dataTransfer.files);
  };
 
  return (
    <>
      {label && <label htmlFor="search-select">{label}</label>}
      <input
        type="file"
        onChange={_onChange}
        // value={value}
        onDrop={_onDrop}
        multiple
        className="documentBackground"
      />
    </>
  );
});

DocumentUpload.propTypes = { 
  onChange: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default DocumentUpload;
