import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
// import UserDefault from '../../../assets/user-default.png';
import UserDefault from '../../../assets/logos/team-empire-logo-inverted-rgb.png';
import Option from './Option';
// import SettingsIcon from '../../../assets/icons/settings.svg';
import LogoutIcon from '../../../assets/icons/logout.svg';
import UserManageIcon from '../../../assets/icons/topbar/user-management.svg';
import TimeManageIcon from '../../../assets/icons/topbar/time-management.svg';
import { CLEAR_CURRENT_USER } from '../../../actions/types';

const getUserData = createSelector(
  (state) => state.auth,
  (auth) => auth.user,
);

const User = () => {
  const user = useSelector(getUserData);
  console.log({ user });
  const { groupId, name: userName } = user;
  const dispatch = useDispatch();
  const [drop, setDrop] = useState(false);

  const _toggleDrop = () => {
    setDrop(!drop);
  };

  const _logout = () => {
    dispatch({ type: CLEAR_CURRENT_USER });
  };

  return (
    <div className="user-wrapper">
      <div className="user" onClick={_toggleDrop}>
        <p>{userName}</p>
        <div className="avatar">
          <img src={UserDefault} alt="" />
        </div>
      </div>
      {
        drop
        && (
          <>
            <div
              onClick={() => setDrop(false)}
              className="backdrop global-modal-closer" />
            <div className="drop-down card" style={{ zIndex: 20 }}>
              <ul>
                {/* <Option
                title="Settings"
                link="/settings/quickbooks"
                icon={SettingsIcon}
              /> */}
                {
                  (groupId === 1 || groupId === 2) && (
                    <>
                      <Option
                        title="User Management"
                        link="/users"
                        toggle
                        onClick={_toggleDrop}
                        icon={UserManageIcon}
                      />
                      <Option
                        title="Manage Time Slots"
                        link="/settings/manage/time-slots"
                        toggle
                        onClick={_toggleDrop}
                        icon={TimeManageIcon}
                      />
                    </>
                  )
                }
                <Option
                  title="Logout"
                  onClick={_logout}
                  icon={LogoutIcon}
                />
              </ul>
            </div>
          </>
        )
      }
    </div>
  );
};

export default User;
