/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
import React, {
  useMemo, useState, useEffect
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import PropTypes from 'prop-types';
import Table from '../../../../components/Common/Table/Table';
import AssignedAgentCell from '../CellComponents/AssignedAgent';
import LeadStatusCell from '../CellComponents/Status';
import OriginCell from '../CellComponents/Origin';
import TextDataUpdate from '../CellComponents/TextDataUpdate'; 

const getUserData = createSelector(
  (state) => state.auth,
  (auth) => auth.user,
);

const LeadsTable = (props) => {
  const user = useSelector(getUserData);
  const { groupId } = user;
  const {
    leadData, setLeads, category, options, tableHeaders, searchInput, setSearchInput, setUpdate, summary
} = props;
const mergedLeads = [];
const colsFromStorage = JSON.parse(localStorage.getItem('cols') || '[]');

const [checkedCols, setCheckedCols] = useState([]);
const [allLeads, setAllLeads] = useState([]);
const selectedColumns = [];

const mergeData = () => {
  leadData.map((item) => {
     let x = false;
        // eslint-disable-next-line no-shadow
        options.map((option) => {
        if (option) {
          if (item.id === option.id) {
            mergedLeads.push({ ...item, ...option });
            x = true;
          }
        } 
        });
       if (!x) {
        mergedLeads.push(item);
       }
    });
    setAllLeads([...mergedLeads]);
  };
  const setColumns = () => {
    if (colsFromStorage.length > 0) {
        setCheckedCols(colsFromStorage);
    } else {
      setCheckedCols(tableHeaders);
    }
  };

  const columns = useMemo(
    () => [      
      {
          id: 'leadTitle',
          Header: 'Lead Name',
          accessor: 'leadTitle',
          isVisible: 1,
          width: 360,
          Cell: (row) => {
            const { row: rowData } = row;           
            return (                 
              <TextDataUpdate
                columnId="leadTitle"
                rowData={rowData.original}
                clickedRow={rowData.original.id}
                setLeads={setLeads}
                value={rowData.original.leadTitle}
                category={category} />              
            );
         },
         sticky: 'left',
      },
      {
          id: 'assignedAgent',
          Header: 'Owner',
          accessor: 'assignedAgent',
          isVisible: 1,
          Cell: (row) => {
            const { row: rowData } = row;
            return (
              <AssignedAgentCell
                rowData={rowData.original}
                clickedRow={rowData.original.id}
                setLeads={setLeads}
                category={category}
                />
  
            );
          },
          sticky: 'left',
          show: (groupId === 1 || groupId === 2),
      },
      {
          id: 'email',
          Header: 'Email',
          accessor: 'email',
          isVisible: 1,
          Cell: (row) => {
             const { row: rowData } = row;
             return (
               <TextDataUpdate
                 columnId="email"
                 rowData={rowData.original}
                 clickedRow={rowData.original.id}
                 setLeads={setLeads}
                 value={rowData.original.email}
                 category={category} />
             );
          },
         
          // Cell: EditableCell,
      },
      {
        id: 'contactNumber',
        Header: 'Phone Number',
        accessor: 'contactNumber',
        isVisible: 1,
        maxWidth: 250,
        Cell: (row) => {
          const { row: rowData } = row;
          return (
            <TextDataUpdate
              columnId="contactNumber"
              rowData={rowData.original}
              clickedRow={rowData.original.id}
              setLeads={setLeads}
              value={rowData.original.contactNumber}
              category={category} />
          );
        }
    },
      {
         id: 'status',
         className: 'selection-column',
         Header: 'Status',
         accessor: 'status',
         maxWidth: 150,
         isVisible: 1,
         Cell: (row) => {
           const { row: rowData } = row;
           return (
             <LeadStatusCell
               rowData={rowData.original}
               clickedRow={rowData.original.id}
               setLeads={setLeads}
               category={category}
             />

           );
         },         
     },    
    {
        id: 'city',
        Header: 'Area',
        accessor: 'city',
        isVisible: 1,
        maxWidth: 200,
        Cell: (row) => {
          const { row: rowData } = row;
          return (
            <TextDataUpdate
              columnId="city"
              rowData={rowData.original}
              clickedRow={rowData.original.id}
              setLeads={setLeads}
              value={rowData.original.city}
              category={category} />
          );
        }
    },
    {
        id: 'origin',
        Header: 'Lead Origin',
        accessor: 'origin',
        isVisible: 1,
        maxWidth: 300,
        Cell: (row) => {
          const { row: rowData } = row;
          return (
            <OriginCell
              rowData={rowData.original}
              clickedRow={rowData.original.id}
              setLeads={setLeads}
              category={category}
           />
          );
        }
      },      
    // {
    //     id: 'assignedAgent',
    //     Header: 'Owner',
    //     accessor: 'assignedAgent',
    //     isVisible: 1,
    //     Cell: (row) => {
    //       const { row: rowData } = row;
    //       return (
    //         <AssignedAgentCell
    //           rowData={rowData.original}
    //           clickedRow={rowData.original.id}
    //           setLeads={setLeads}
    //           category={category}
    //           />
    //       );
    //     },         
    //     show: (groupId === 1 || groupId === 2),
    // },            
    ],
  []
);

checkedCols.map((col) => {
  if (col.checked) {
    const headerTitle = col.field_title;
    selectedColumns.push({
      id: col.field_title,
      Header: col.field_title,
      accessor: col.field_title,
      isVisible: 1,
      Cell: (row) => {
       const { row: rowData } = row;    
       return (
         <TextDataUpdate
           columnId={col.field_title}
           id={col.id}
           rowData={rowData.original}
           clickedRow={rowData.original.id}
           setLeads={setLeads}
           value={rowData.original[headerTitle]}
           category={category} />
       );
     }
     });
  } 
});

const columnArray = [...columns, ...selectedColumns];

useEffect(() => {
  mergeData();
  setColumns();
}, [leadData]);

return (
  <>    
    <Table
      columns={columnArray}
      tableData={allLeads}
      tableHeaders={tableHeaders}
      checkedColumns={checkedCols}
      setCheckedColumns={setCheckedCols}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      tableName="leads"
      category={category}
      setLeads={setLeads}
      setUpdate={setUpdate}
      summary={summary}
      activateTopActions={1}
      activateSearch={1}
      activatePagination={1}
      activateFilters={0}
      activateSelection={1}
      activateColumns={1}
    />
  </>            
  );
};
        
LeadsTable.propTypes = {
leadData: PropTypes.array.isRequired,
setLeads: PropTypes.func.isRequired,
category: PropTypes.string.isRequired,
options: PropTypes.array.isRequired,
tableHeaders: PropTypes.array.isRequired,
searchInput: PropTypes.func.isRequired,
setSearchInput: PropTypes.func.isRequired,
setUpdate: PropTypes.func.isRequired,
summary: PropTypes.array.isRequired,
};
  
export default LeadsTable;
