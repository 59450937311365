const validate = (tokenPayload) => {
  const { defaultSettings, client } = tokenPayload;

  if (!defaultSettings || !client) {
    return false;
  }

  const {
    defaultCurrency, customerPortalSettings, documentsSettings, salesDealSettings,
  } = defaultSettings;

  if (!defaultCurrency || !customerPortalSettings || !documentsSettings || !salesDealSettings) {
    return false;
  }

  return true;
};

export default validate;
