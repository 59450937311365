import React from 'react';
import PropTypes from 'prop-types';
// import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import Topbar from '../Topbar';

// const getSidebarWidth = createSelector(
//   (state) => state.sidebar,
//   (sidebar) => sidebar.width,
// );

const Container = (props) => {
  const { children } = props;
  // const sidebarWidth = useSelector(getSidebarWidth);
  const contentWidth = 'calc(100vw)';

  const dark = useSelector((state) => state.themeToggle.dark);

  return (
    <div className="main-content" data-dark={dark} style={{ width: contentWidth }}>
      <Topbar />
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
