import React from 'react';
import Form from './Form';
import Features from './Features';

const SignIn = () => (
  <div className="auth-screen">
    <Form />
    <Features />
  </div>
  );

export default SignIn;
