import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import LabeledInput from '../../../components/InputFields/LabeledInput';
import FullLogo from '../../../assets/logos/salessuite-logo.png';
import axios from '../../../utils/axios';
import { SET_CURRENT_USER } from '../../../actions/types';

const Form = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState('');

  const _onSubmit = async (e) => {
    e.preventDefault();
    const data = { username, password };
    try {
      const res = await axios.post('/auth/login', data);
      const { token } = res.data;
      localStorage.setItem('jwtToken', token);
      dispatch({ type: SET_CURRENT_USER, payload: token });
    } catch (err) {
      setIsValid(false);
      setError(err.response.data.message);
      console.log(err);
    }
  };

  return (
    <div className="form-wrapper">
      <div className="auth-form">
        <div className="header">
          <img src={FullLogo} alt="logo" />
          <h4>Welcome back! Please login to continue.</h4>
        </div>
        <form onSubmit={_onSubmit}>
          <LabeledInput
            id="email"
            value={username}
            onChange={setUsername}
            // label="Email"
            placeholder="Username"
            // type="email"
          />
          <LabeledInput
            id="password"
            value={password}
            onChange={setPassword}
            // label="Password"
            placeholder="Password"
            type="password"
          />
          {
            !isValid
            && (
              <div className="error-message">
                <h4>{error}</h4>
              </div>
            )
          }
          <div className="button-container">
            <button type="submit" className="btn btn-accent btn-lg">
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
