import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios';
import UniversalSearchInput from '../../components/InputFields/UniversalSearchInput';
import DropDownList from './DropDownList';

const SearchBar = () => {
  const [searchText, setSearchText] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [items, setItems] = useState([]);
  const [drop, setDrop] = useState(false);

  const history = useHistory();

  const _setSearchSelect = (key) => {
    setSearchText(key);
    if (key.length > 0) {
      const filteredData = items.sort().filter((value) => value.name.toLowerCase().includes(key.toLowerCase()));
      setSuggestions(filteredData);
      if (!drop) {
        setDrop(!drop);
      }
    } else {
      setSuggestions([]);
    }
  };
 
  const suggestionSelected = (item) => {
    setSearchText(item.name);
    setSuggestions([]);
    setDrop(!drop);

    if (item.type === 'Sales Group') {
      history.push(`/sales-group/profile/${item.id}`);
    } else if (item.type === 'Sales Team') {
      history.push(`/sales-team/profile/${item.id}`);
    } else {
      history.push(`/universal-search/${item.type}/${item.id}`);
    }
  };

  const clearSearch = () => {
    setSearchText('');
    setSuggestions([]);
    setDrop(!drop);
  };

  const renderSuggestions = () => {
    if (suggestions.length === 0) {
      return null;
    }

    return (
      <DropDownList
        suggestions={suggestions}
        suggestionSelected={suggestionSelected}
      />
    );
  };

  const getList = async () => {
    try {
      const res = await axios.get('/universal/search');
      console.log(res.data);
      setItems(res.data);
    } catch (error) {
      console.log(error);
      // if (error.response.data.type === 'INVALID_TOKEN') {
      //   clearUserDispatch();
      // }
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className="auto-complete" style={{ width: '100%' }}>
      <UniversalSearchInput
        id="universalSearch"
        onChange={_setSearchSelect}
        value={searchText}
        placeholder="Search..."
        clearSearch={clearSearch}
      />
      {
        drop
        && (
          renderSuggestions()
        )
      }
    </div>
  );
};

export default SearchBar;
