import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import axios from '../../../../utils/axios';
import useNotificationDispatcher from '../../../../hooks/useNotificationDispatcher';

const LeadOriginList = (props) => {
    const { setFilteItem } = props;
    const [leadOriginList, setLeadOriginList] = useState([]);
    const [dateReceive, setDateReceive] = useState(false);

    const notificationDispatch = useNotificationDispatcher();

    const getleadOriginList = async () => {
        try {
            const res = await axios.get('leads/lead-origin');
            console.log('origin data', res.data);
            setLeadOriginList(res.data);
            setDateReceive(true);
        } catch (error) {
            console.log(error);
            notificationDispatch('error', 'Something went wrong!');
        }
    };

    const onItemClick = (item) => {
        setFilteItem(item);
    };
    
useEffect(() => {
    getleadOriginList();
}, []);
    return (
      <>
        {
            dateReceive && (
            <div className="drop-down card">
              <ul>
                {leadOriginList.map(
                            (item, i) => (
                              <li
                                key={i}
                                onClick={() => onItemClick(item)}>
                                <>
                                  {item.label}
                                </>
                              </li>
                            )
                        )}
              </ul>
            </div>
            )
        }
      </>
    );
};

LeadOriginList.propTypes = {
    setFilteItem: PropTypes.func.isRequired,
};
export default LeadOriginList;
