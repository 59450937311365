import React from 'react';
import PropTypes from 'prop-types';

const TextAreaInput = (props) => {
  const {
    id,
    label,
    placeholder,
    message,
    onChange,
    onFocus,
    value,
    isDisabled,
    required,
    additionalDivStyle,
    additionalTextAreaStyle,
  } = props;
  const _onChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <div className={`form-group ${additionalDivStyle}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <textarea
        value={value}
        // type={type}
        onFocus={onFocus}
        className={`form-control-textarea ${additionalTextAreaStyle}`}
        id={id}
        aria-describedby={label}
        placeholder={placeholder}
        onChange={_onChange}
        disabled={isDisabled}
        required={required}
      />
      {
        message && (
          <small
            id={id}
            className="form-text text-muted"
          >
            {message}
          </small>
        )
      }
    </div>
  );
};

TextAreaInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  message: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  additionalDivStyle: PropTypes.string,
  additionalTextAreaStyle: PropTypes.string,
};

TextAreaInput.defaultProps = {
  message: null,
  required: false,
  label: '',
  placeholder: '',
  additionalDivStyle: '',
  additionalTextAreaStyle: '',
};

export default React.memo(TextAreaInput);
