import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import axios from '../../../../utils/axios';
import useNotificationDispatcher from '../../../../hooks/useNotificationDispatcher';

const LeadAgentsList = (props) => {
  const { setFilteItem } = props;
  const [agentsList, setAgentsList] = useState([]);
  const [dataReceive, setDataReceive] = useState(false);

  const notificationDispatch = useNotificationDispatcher();

  const getLeadAgentList = async () => {
    try {
      const res = await axios.get('/users/agent/type/lead');
      setAgentsList(res.data);
      setDataReceive(true);
    } catch (error) {
      console.log(error);
      notificationDispatch('error', 'Something went wrong!');
    }
  };

  const onItemClick = (item) => {
    setFilteItem(item);
  };

  useEffect(() => {
    getLeadAgentList();
  }, []);

  return (
    <>
      {
        dataReceive && (
          <div className="drop-down card">
            <ul>
              {agentsList.map(
                (item, i) => (
                  <li
                    key={i}
                    onClick={() => onItemClick(item)}>
                    <>
                      {item.label}
                    </>
                  </li>
                )
              )}
            </ul>
          </div>
        )
      }
    </>
  );
};

LeadAgentsList.propTypes = {
  setFilteItem: PropTypes.func.isRequired,
};

export default LeadAgentsList;
