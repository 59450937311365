import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const Option = (props) => {
  const {
    title,
    icon,
    link,
    onClick,
    toggle,
  } = props;
  const history = useHistory();

  const _onClick = () => {
    if (link) {
      if (toggle) {
        onClick();
      }
      history.push(link);
    } else {
      onClick();
    }
  };

  return (
    <li onClick={_onClick}>
      <img src={icon} alt="option" />
      {title}
    </li>
  );
};

Option.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  onClick: PropTypes.func,
  toggle: PropTypes.bool,
};

export default Option;
