import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '../../assets/icons/topbar/search.svg';
import ClearIcon from '../../assets/icons/topbar/clear.svg';

const UniversalSearchInput = (props) => {
  const {
    id,
    type,
    label,
    placeholder,
    onChange,
    onFocus,
    value,
    isDisabled,
    clearSearch,
  } = props;
  
  const _onChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <div className="search-bar card card-body">
      {/* {label && <label htmlFor={id}>{label}</label>} */}
      <div className="search-img-container">
        <img src={SearchIcon} className="search-img" alt="search" />
      </div>
      <input
        onChange={_onChange}
        value={value}
        type={type}
        onFocus={onFocus}
        className="form-controlx"
        id={id}
        aria-describedby={label}
        placeholder={placeholder}
        disabled={isDisabled}
        autoComplete="off"
      />
      <div className="clear-img-container">
        <img src={ClearIcon} className="clear-img" alt="clear" onClick={() => clearSearch()} />
      </div>
    </div>
  );
};

UniversalSearchInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  isDisabled: PropTypes.bool,
  clearSearch: PropTypes.func,
};

UniversalSearchInput.defaultProps = {
  type: 'text',
  label: '',
  placeholder: '',
};

export default UniversalSearchInput;
