import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';

const MenuList = (props) => {
  const { children } = props;

  if (!children.length) {
    return (<div className="myClassListName">{children}</div>);
  }

  const height = 45;

  return (
    <List
      height={200}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={0}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

MenuList.propTypes = {
  children: PropTypes.array.isRequired,
};

export default MenuList;
