import React from 'react';
import PropTypes from 'prop-types';
import SuggestionCard from './SuggestionCard';

const WorkflowSuggestions = (props) => {
  const { setShowCircle } = props;
  return (
    <div className="suggestions-container">
      <div>
        <h6 className="day">Today</h6>
      </div>

      <div className="card_container">
        <SuggestionCard
          title="Email &apos;Robert Ericsson&apos;"
          time="3 pm"
          actions={['Schedule Email', 'Send Email Now', 'Ignore Suggestion']}
        />

        <SuggestionCard
          title="Schedule a meeting with &apos;Emma Brown&apos;"
          time="4 pm"
          actions={['Schedule Email', 'Start Meeting Now', 'Ignore Suggestion']}
        />

        <SuggestionCard
          title="Call &apos;Alice White&apos;"
          time="3 pm"
          actions={['Schedule Call', 'Call Now', 'Ignore Suggestion']}
        />

        <SuggestionCard
          title="Email &apos;Robert Ericsson&apos;"
          time="3 pm"
          actions={['Schedule Email', 'Send Email Now', 'Ignore Suggestion']}
        />

      </div>

      <button type="button" className="suggestions_back" onClick={() => setShowCircle(true)}>&lt;&nbsp; BACK</button>
    </div>
);
};

WorkflowSuggestions.propTypes = {
  setShowCircle: PropTypes.bool
};

export default WorkflowSuggestions;
