import React from 'react';
import PropTypes from 'prop-types';

const SuggestionCard = (props) => {
  const { title, time, actions } = props;
  return (
    <div className="card">
      <div className="card_header">
        <h1 className="title">{title}</h1>
        <h1 className="time">{time}</h1>
      </div>
      <div className="card_actions">
        { actions.map((action, i) => {
          const colorStyles = ['cyan', 'yellow', 'red'];
          return <div className={`action action-${colorStyles[i % 3]}`}>{action}</div>;
        }) }
      </div>
    </div>

  );
};

SuggestionCard.propTypes = {
  title: PropTypes.string,
  time: PropTypes.string,
  actions: PropTypes.array
};

export default SuggestionCard;
