import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { darkThemeOff, scrollDown } from '../../../actions/types';
import WorkflowSuggestions from './WorkflowSuggestions';

const ScrollModal = () => {
  const [showCircle, setShowCircle] = useState(true);
  const [clusterCircles, setClusterCircles] = useState([]);
  const [clusterCircleCount, setClusterCircleCount] = useState([]);

  const dispatch = useDispatch();

  const scroll = useSelector((state) => state.modalScroll.scroll);

  const handleClick = () => {
    dispatch(darkThemeOff());
    dispatch(scrollDown());
  };

  const getClusterData = async () => {
    // fake api call
    const res = await axios.get(
      'http://localhost:8081/web/ai/cluster'
    );

    const clusterInfo = (clusterObject) => {
      const values = Object.values(clusterObject);
      const clusters = values.map((cluster) => cluster.split(' ')[1]);

      const clusterTypesSet = new Set(clusters);
      const clusterTypes = [...clusterTypesSet].sort();

      const clusterCount = clusterTypes.map(
        (clusterType) => clusters.filter((cluster) => clusterType === cluster).length
      );

      return { clusterTypes, clusterCount };
    };

    const result = clusterInfo(res.data.Cluster);

    setClusterCircles(result.clusterTypes);
    setClusterCircleCount(result.clusterCount);
  };

  useEffect(
    () => {
      getClusterData();
    }, []
  );

  let circleItems;
  if (clusterCircles.length && clusterCircleCount.length) {
    const circleStyles = ['green', 'sky', 'orangered', 'yellow', 'cyan'];
    const sum = clusterCircleCount.slice(0, 5).reduce((acc, value) => acc + value, 0);
    const ratio = clusterCircleCount.slice(0, 5).map((count) => count / sum);

    circleItems = clusterCircles.slice(0, 5).map((cluster, i) => {
      // Change font size dynamically on css according to the no of cluster count
      const circleText = {
        fontSize: `${ratio[i] * 2.5 <= 1 ? 1 : ratio[i] * 3}rem`
      };
      const circleDimension = {
        width: `${ratio[i] * 28}rem`, height: `${ratio[i] * 28}rem`
      };

      return (
        <div className="circles">
          <div style={circleDimension} className={`circle circle-${circleStyles[i]}`} onClick={() => setShowCircle(false)}>
            <h6 className="clusterName" style={circleText}>Cluster {cluster}</h6>
            <div className="suggestion">
              <h6 className="clusterCount" style={circleText}>{clusterCircleCount[i]}</h6>
              <h6 style={circleText}>30% more deals</h6>
            </div>
          </div>
        </div>
    );
  });
  }

  return (
    <div className="scrollModal" data-scroll={scroll}>
      <span className="close" onClick={handleClick}>&times;</span>
      <h1 className="title">Workflow suggestion based on clusters</h1>

      {showCircle
        ? (
          <>
            <div className="circles">
              {circleItems}
            </div>
          </>
        )
        : <WorkflowSuggestions setShowCircle={setShowCircle} />}

    </div>
  );
};

export default ScrollModal;
