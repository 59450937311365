import React from 'react'
import DocViewer, { DocViewerRenderers  } from "react-doc-viewer";
import PropTypes from 'prop-types';

const DocumentPreview = (props) => {
    const { docs } = props;
    return (
        <div>
            <DocViewer 
            pluginRenderers={DocViewerRenderers} 
            documents={docs}  
            className="file-preview" 
            config={{
                header: {
                disableHeader: true,
                }
            }} />
        </div>
    )
}

DocumentPreview.propTypes = {
    docs: PropTypes.array.isRequired,
  };

export default DocumentPreview
