import React from 'react';
import PropTypes from 'prop-types';

const ConfirmDelete = (props) => {
  const {
    closeModal, deleteLead
  } = props;

  return (
    <>
      <div className="row">
        <div className="col-9">
          <h2>Are you sure you want to delete this lead?</h2>
        </div>
        <div className="col-1">
          <button type="button" className="btn btn-crm-maroon float-right ays-btn-yes" onClick={deleteLead}> Yes </button>
        </div>
        <div className="col-1 ml-4">
          <button type="button" className="btn btn-crm-maroon float-right ays-btn-cancel" onClick={closeModal}> Cancel </button>
        </div>
      </div>
    </>
  );
};

ConfirmDelete.propTypes = {
    closeModal: PropTypes.func.isRequired,
  deleteLead: PropTypes.func.isRequired,
};

export default ConfirmDelete;
