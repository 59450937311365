import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import Loader from '../Loaders';

const getAuthStatus = createSelector(
  (state) => state.auth,
  (auth) => auth,
);

const CommonRoute = (props) => {
  const { component: Component, path } = props;
  const auth = useSelector(getAuthStatus);
  const { isAuthenticated } = auth;

  const route = '/dashboard';

  return (
    <Route
      exact
      path={path}
      render={() => (
        isAuthenticated
          ? (
            <Redirect
              to={route}
            />
          )
          : (
            <>
              <Suspense fallback={<Loader />}>
                <Component />
              </Suspense>
            </>
          ))}
    />
  );
};

CommonRoute.propTypes = {
  component: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};

export default CommonRoute;
