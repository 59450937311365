const initialState = {
  scroll: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SCROLL_UP':
      console.log(`%c ${state.scroll}`, 'background-color:green;');
      return { ...state, scroll: 1 };
    case 'SCROLL_DOWN':
      console.log(state.scroll);
      return { ...state, scroll: 0 };
    case 'SCROLL_HIDE':
      console.log(state.scroll);
      return { ...state, scroll: undefined };
    default:
      return state;
  }
};
