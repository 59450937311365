import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';

const CountryCodeInput = (props) => {
  const {
    id,
    type,
    placeholder,
    onChange,
    specialLabel,
    name,
    minValue,
    maxValue,
    value,
    country,
    errorMessage,
  } = props;

  const _onChange = (e) => {
    onChange(e);
  };

  return (
    <>
      <label>Contact Number</label>
      <div className="form-group-phone">
        <PhoneInput
          id={id}
          type={type}
          placeholder={placeholder}
          onChange={_onChange}
          specialLabel={specialLabel}
          name={name}
          minValue={minValue}
          maxValue={maxValue}
          value={value}
          country={country}
        />
      </div>
      {
        errorMessage && (
          <small
            className="form-text text-warning country-code-warning-message"
          >
            * {errorMessage}
          </small>
        )
      }
    </>
  );
};

CountryCodeInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  specialLabel: PropTypes.string,
  name: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  value: PropTypes.string,
  country: PropTypes.string,
  errorMessage: PropTypes.string,
};

CountryCodeInput.defaultProps = {
  type: 'text',
  specialLabel: 'Contact Number',
  placeholder: '',
  errorMessage: '',
};

export default React.memo(CountryCodeInput);
