import {
  SET_ERROR_MESSAGE,
  CLEAR_ERROR_MESSAGE,
  SET_SUCCESS_MESSAGE,
  CLEAR_SUCCESS_MESSAGE,
  SET_INFO_MESSAGE,
  CLEAR_INFO_MESSAGE,
} from '../actions/types';

const initialState = {
  success: '',
  error: '',
  info: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      return {
        success: '',
        error: action.payload,
        info: '',
      };
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        error: '',
      };
    case SET_SUCCESS_MESSAGE:
      return {
        success: action.payload,
        error: '',
        info: '',
      };
    case CLEAR_SUCCESS_MESSAGE:
      return {
        ...state,
        success: '',
      };
    case SET_INFO_MESSAGE:
      return {
        success: '',
        error: '',
        info: action.payload,
      };
    case CLEAR_INFO_MESSAGE:
      return {
        ...state,
        info: '',
      };
    default:
      return state;
  }
};
