import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import CalendarIcon from '../../../assets/icons/topbar/crm-calendar-green.svg';
import HomeIcon from '../../../assets/icons/topbar/crm-home.svg';
import LeadsIcon from '../../../assets/icons/topbar/crm-lead.svg';
import DealsIcon from '../../../assets/icons/topbar/crm-deal.svg';
import CustomersIcon from '../../../assets/icons/topbar/crm-customer.svg';
import NavItem from './NavItem';
// import NavSubItem from './NavSubItem';

const getUserData = createSelector(
  (state) => state.auth,
  (auth) => auth.user,
);

const Navigation = () => {
  const user = useSelector(getUserData);
  const { groupId } = user;

  const [selectedNav, setSelectedNav] = useState('');

  // const _onMouseLeave = () => {
  //   setSelectedNav(null);
  // };

  return (
    <>
      <nav>
        <div className="nav">
          <div className="list-items">
            <ul>
              <NavItem
                link="/dashboard"
                label="Home"
                icon={HomeIcon}
                disabledFor={[]}
                groupId={groupId}
                selectedNav={selectedNav}
                setSelectedNav={setSelectedNav}
              />
              <NavItem
                link="/leads/main"
                label="Leads"
                icon={LeadsIcon}
                disabledFor={[4]}
                groupId={groupId}
                selectedNav={selectedNav}
                setSelectedNav={setSelectedNav}
              />
              <NavItem
                link="/sales-deal/main/All Deals/0/view-as/0/All"
                label="Deals"
                icon={DealsIcon}
                disabledFor={[3]}
                groupId={groupId}
                selectedNav={selectedNav}
                setSelectedNav={setSelectedNav}
              />
              {/* <NavItem>
                <div className="top-nav-drop-down nav-sub-curve" onMouseLeave={_onMouseLeave}>
                  <NavSubItem link="#" label="Start Deal" disabledFor={[3]} groupId={groupId} setSelectedNav={setSelectedNav} />
                </div>
              </NavItem> */}
              <NavItem
                link="/customers"
                label="Customers"
                icon={CustomersIcon}
                disabledFor={[3]}
                groupId={groupId}
                selectedNav={selectedNav}
                setSelectedNav={setSelectedNav}
              />
              <NavItem
                link="/aggregated-calendar"
                label="Calendar"
                icon={CalendarIcon}
                groupId={groupId}
                selectedNav={selectedNav}
                setSelectedNav={setSelectedNav}
              />
              {/* <NavItem
                link="/hubs/emails"
                label="Hubs"
                icon={icon}
                disabledFor={[3]}
                groupId={groupId}
                selectedNav={selectedNav}
                setSelectedNav={setSelectedNav}
                addStyle="float-right"
              /> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
