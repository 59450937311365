import React, { lazy } from 'react';
import jwt from 'jsonwebtoken';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'react-calendar/dist/Calendar.css';

import './styles/app.scss';
// import 'react-table/react-table.css';

import PrivateRoutes from './components/Routers/PrivateRoutes';
import CommonRoute from './components/Routers/CommonRoute';
import store from './store';
import Login from './pages/Auth/Login';
import setAuthToken from './utils/setAuthToken';
import { SET_CURRENT_USER } from './actions/types';
import validateAuthTokenFields from './validation/validateAuthTokenFields';
import NewForm from './pages/Forms/StartForm';

//Public Page
import PublicPage from './pages/PublicPage';
import Leads from './pages/Leads/Main';

// DASHBOARD
const Dashboard = lazy(() => import('./pages/Dashboard'));
const QuickbooksSettings = lazy(() => import('./pages/Settings/Quickbooks'));
const TimeSlotManage = lazy(() => import('./pages/Settings/TimeSlotManage'));

// Aggregated Calendar
const AggregatedCalendar = lazy(() => import('./pages/AggregatedCalendar/Main'));

// USERS
const UserGroups = lazy(() => import('./pages/UserGroups/Main'));
const AddUserGroups = lazy(() => import('./pages/UserGroups/AddUserGroups'));
const UsersMain = lazy(() => import('./pages/Users/Main'));
const AddUser = lazy(() => import('./pages/Users/AddUser'));
const EditUser = lazy(() => import('./pages/Users/EditUser'));

// SALES GROUPS
const SalesGroups = lazy(() => import('./pages/SalesGroups/Main'));
const AddSalesGroups = lazy(() => import('./pages/SalesGroups/AddSalesGroups'));
const SalesGroupProfile = lazy(() => import('./pages/SalesGroups/Profile'));

// SALES TEAMS
const SalesTeams = lazy(() => import('./pages/SalesTeams/Main'));
const AddSalesTeams = lazy(() => import('./pages/SalesTeams/AddSalesTeams'));
const SalesTeamProfile = lazy(() => import('./pages/SalesTeams/Profile'));

// AGENTS
const Agents = lazy(() => import('./pages/Agents/Main'));
const AddAgents = lazy(() => import('./pages/Agents/AddAgents'));
const AgentProfile = lazy(() => import('./pages/Agents/Profile'));

// CUSTOMERS
const AddCustomerCategories = lazy(() =>
  import('./pages/CustomerCategories/AddCustomerCategories')
);
const Customers = lazy(() => import('./pages/Customers/Main'));
const AddCustomer = lazy(() => import('./pages/Customers/AddCustomers'));
const CustomerProfile = lazy(() => import('./pages/Customers/Profile'));

// PRODUCTS
const AddProduct = lazy(() => import('./pages/Products/AddProduct'));

// ROUTES AND VISITS
const RoutesAndVisits = lazy(() => import('./pages/RoutesAndVisits/Main'));
const RoutesAndVisitsProfile = lazy(() =>
  import('./pages/RoutesAndVisits/Profile')
);
const RoutesProfile = lazy(() =>
  import('./pages/RoutesAndVisits/AgentRoutes/Profile')
);
const AddRoute = lazy(() =>
  import('./pages/RoutesAndVisits/AgentRoutes/AddRoutes')
);
const UnassignedRoutesAndVisits = lazy(() =>
  import('./pages/Unassigned/RoutesAndVisits/Main')
);
const AddUnassignedRoutes = lazy(() =>
  import('./pages/Unassigned/RoutesAndVisits/UnallocatedRoutes/AddRoute')
);
const UnassignedRouteProfile = lazy(() =>
  import('./pages/Unassigned/RoutesAndVisits/UnallocatedRoutes/Profile')
);
const VisitDetails = lazy(() =>
  import('./pages/RoutesAndVisits/AgentVisits/Main')
);
const VisitDetailsProfile = lazy(() =>
  import('./pages/RoutesAndVisits/AgentVisits/VisitDetails')
);

// TASKS
const Tasks = lazy(() => import('./pages/Tasks/Main'));
const AgentTasks = lazy(() => import('./pages/Tasks/Profile'));
const AgentDelayedTasks = lazy(() => import('./pages/Tasks/DelayedTasks'));

// Surveys
const Surveys = lazy(() => import('./pages/Surveys/Main'));
const CreateSurvey = lazy(() => import('./pages/Surveys/CreateSurvey'));
const SubmittedSurvey = lazy(() => import('./pages/Surveys/Submitted'));
const SurveyProfile = lazy(() => import('./pages/Surveys/Profile'));
const EditSurvey = lazy(() => import('./pages/Surveys/Profile/EditSurvey'));

// CATALOGUE
const Catalogue = lazy(() => import('./pages/Catalogue/Main'));
const AddCatalogue = lazy(() => import('./pages/Catalogue/AddProducts'));
const ViewProducts = lazy(() => import('./pages/Catalogue/ViewProducts'));
const EditProducts = lazy(() => import('./pages/Catalogue/EditProducts'));

// PROMOTION
const Promotion = lazy(() => import('./pages/Promotion/Main'));
const AddPromotion = lazy(() => import('./pages/Promotion/AddPromotions'));
const ViewPromotions = lazy(() => import('./pages/Promotion/ViewPromotions'));
const EditPromotions = lazy(() => import('./pages/Promotion/EditPromotions'));

// CRM
const SalesFunnel = lazy(() => import('./pages/SalesFunnel/Main'));
const SalesFunnelLeads = lazy(() => import('./pages/SalesFunnel/Leads'));
const OngoingQuotations = lazy(() => import('./pages/SalesFunnel/Ongoing/Quotations'));
const Quotations = lazy(() => import('./pages/Quotations/Main'));
const QuotationProfile = lazy(() => import('./pages/Quotations/Profile'));
const SalesDealsMain = lazy(() => import('./pages/SalesDeals/Main'));
const StartDeal = lazy(() => import('./pages/SalesDeals/StartDeal/Main'));
const DealProfile = lazy(() => import('./pages/SalesDeals/Profile'));
const TasksHub = lazy(() => import('./pages/Hubs/Tasks'));
const EmailsHub = lazy(() => import('./pages/Hubs/Emails'));
const MeetingsHub = lazy(() => import('./pages/Hubs/Meetings'));
// const RequirementGather = lazy(() => import('./pages/StartLead/RequirementGathering'));
// const DataGather = lazy(() => import('./pages/StartLead/DataGathering1'));
const LeadsMain = lazy(() => import('./pages/Leads/Main'));
const StartLead = lazy(() => import('./pages/Leads/StartLead'));
const FormMain = lazy(() => import('./pages/Forms/Main'));

//UNIVERSAL SEARCH
const UniversalSearch= lazy(() => import('./pages/UniversalSearch'));

// EMAIL DESIGNER
const EmailDesigner = lazy(() => import('./pages/EmailDesigner'));

const token = localStorage.jwtToken;
// Check for token
if (token) {
  const decoded = jwt.decode(token);
  if (validateAuthTokenFields(decoded)) {
    // Set auth token header auth
    setAuthToken(token);
    // Decode token and get user info and exp
    // const decoded = jwt.decode(token);
    // Set user and isAuthenticated
    store.dispatch({ type: SET_CURRENT_USER, payload: token });

    // // Check for expired token
    // const currentTime = Date.now() / 1000;
    // if (decoded.exp < currentTime) {
    //   // Logout user
    //   store.dispatch(logoutUser());
    //   // Redirect to login
    //   window.location.href = '/';
    // }
  }
}
console.log('loaded');
const App = () => (
  <Provider store={store}>
    <Router>
      <Switch>
        <CommonRoute exact path='/' component={Login} />
        <PrivateRoutes exact path='/dashboard' component={Dashboard} />
        <PrivateRoutes exact path='/aggregated-calendar' component={AggregatedCalendar} />
        <PrivateRoutes
          exact
          path='/settings/quickbooks'
          component={QuickbooksSettings}
        />
        <PrivateRoutes exact path='/settings/manage/time-slots' component={TimeSlotManage} />
        <PrivateRoutes exact path='/universal-search/:type/:id' component={UniversalSearch} />
        <PrivateRoutes exact path='/user-groups' component={UserGroups} />
        <PrivateRoutes
          exact
          path='/user-groups/add'
          component={AddUserGroups}
        />
        <PrivateRoutes exact path='/users' component={UsersMain} />
        <PrivateRoutes exact path='/users/add' component={AddUser} />
        <PrivateRoutes exact path='/users/edit/:id' component={EditUser} />
        <PrivateRoutes exact path='/sales-groups' component={SalesGroups} />

        <PrivateRoutes
          exact
          path='/sales-groups/add'
          component={AddSalesGroups}
        />
        <PrivateRoutes
          exact
          path='/sales-group/profile/:id'
          component={SalesGroupProfile}
        />
        <PrivateRoutes exact path='/sales-teams' component={SalesTeams} />
        <PrivateRoutes
          exact
          path='/sales-teams/add'
          component={AddSalesTeams}
        />
        <PrivateRoutes
          exact
          path='/sales-team/profile/:id'
          component={SalesTeamProfile}
        />
        <PrivateRoutes exact path='/agents' component={Agents} />
        <PrivateRoutes exact path='/agents/add' component={AddAgents} />

        <PrivateRoutes
          exact
          path='/agents/profile/:id'
          component={AgentProfile}
        />
        <PrivateRoutes
          exact
          path='/customer-categories'
          component={AddCustomerCategories}
        />
        <PrivateRoutes
          exact
          path='/customer-categories/add'
          component={AddCustomerCategories}
        />
        <PrivateRoutes exact path='/customers' component={Customers} />
        <PrivateRoutes exact path='/customers/add' component={AddCustomer} />
        <PrivateRoutes
          exact
          path='/customers/profile/:id'
          component={CustomerProfile}
        />
        <PrivateRoutes exact path='/products' component={AddProduct} />
        <PrivateRoutes exact path='/products/add' component={AddProduct} />
        <PrivateRoutes
          exact
          path='/routes-and-visits'
          component={RoutesAndVisits}
        />
        <PrivateRoutes
          exact
          path='/routes-and-visits/profile/agent/:id'
          component={RoutesAndVisitsProfile}
        />
        <PrivateRoutes
          exact
          path='/routes-and-visits/routes/profile/:id'
          component={RoutesProfile}
        />
        <PrivateRoutes
          exact
          path='/add-routes/agent/:id'
          component={AddRoute}
        />
        <PrivateRoutes
          exact
          path='/unassigned/routes-and-visits'
          component={UnassignedRoutesAndVisits}
        />
        <PrivateRoutes
          exact
          path='/add-unassigned-routes'
          component={AddUnassignedRoutes}
        />

        <PrivateRoutes
          exact
          path='/unassigned/routes-and-visits/routes/profile/:id'
          component={UnassignedRouteProfile}
        />
        <PrivateRoutes exact path='/visit-details' component={VisitDetails} />
        <PrivateRoutes
          exact
          path='/visit-details/profile/agent/:id'
          component={VisitDetailsProfile}
        />
        <PrivateRoutes exact path='/tasks/agent/:id' component={AgentTasks} />
        <PrivateRoutes
          exact
          path='/tasks/agent/:id/delayed'
          component={AgentDelayedTasks}
        />
        <PrivateRoutes exact path='/tasks' component={Tasks} />
        <PrivateRoutes exact path='/surveys' component={Surveys} />
        <PrivateRoutes exact path='/surveys/create' component={CreateSurvey} />
        <PrivateRoutes
          exact
          path='/surveys/submited/:id'
          component={SubmittedSurvey}
        />
        <PrivateRoutes
          exact
          path='/surveys/profile/:id'
          component={SurveyProfile}
        />
        <PrivateRoutes exact path='/catalogue' component={Catalogue} />
        <PrivateRoutes exact path='/catalogue/add' component={AddCatalogue} />
        <PrivateRoutes
          exact
          path='/catalogue/view-products/:id'
          component={ViewProducts}
        />
        <PrivateRoutes
          exact
          path='/catalogue/edit-products/:id'
          component={EditProducts}
        />
        <PrivateRoutes exact path='/promotion' component={Promotion} />
        <PrivateRoutes exact path='/promotion/add' component={AddPromotion} />
        <PrivateRoutes
          exact
          path='/promotion/view-products/:id'
          component={ViewPromotions}
        />
        <PrivateRoutes
          exact
          path='/promotion/edit-promotions/:id'
          component={EditPromotions}
        />
        <PrivateRoutes
          exact
          path='/surveys/edit-survey/:id'
          component={EditSurvey}
        />
        <PrivateRoutes
          exact
          path='/sales-funnel'
          component={SalesFunnel}
        />
        <PrivateRoutes
          exact
          path='/sales-funnel/leads/:type/:tId/:lt/:rt/'
          component={SalesFunnelLeads}
        />
        <PrivateRoutes
          exact
          path='/sales-funnel/ongoing/quotations'
          component={OngoingQuotations}
        />
        <PrivateRoutes
          exact
          path='/quotations'
          component={Quotations}
        />
        <PrivateRoutes
          exact
          path='/quotations/profile/:id'
          component={QuotationProfile}
        />
        <PrivateRoutes
          exact
          path='/start-deal'
          component={StartDeal}
        />
        <PrivateRoutes
          exact
          path='/new-form'
          component={NewForm}
        />
        <PrivateRoutes
          exact
          path='/sales-deal/:id/profile'
          component={DealProfile}
        />
        <PrivateRoutes
          exact
          path='/sales-deal/main/:filter/:filterId/view-as/:viewAsId/:viewAsType'
          component={SalesDealsMain}
        />
        <PrivateRoutes
          exact
          path='/leads/main'
          component={LeadsMain}
        />
        <PrivateRoutes
          exact
          path='/leads/main/filter-by/:leadId'
          component={LeadsMain}
        />
        <PrivateRoutes
          exact
          path='/leads/start-lead/:formId'
          component={StartLead}
        />
         <PrivateRoutes
          exact
          path='/forms'
          component={FormMain}
          />
        <PrivateRoutes
          exact
          path='/hubs/tasks'
          component={TasksHub}
        />
        <PrivateRoutes
          exact
          path='/hubs/emails'
          component={EmailsHub}
        />
        <PrivateRoutes
          exact
          path='/hubs/meetings'
          component={MeetingsHub}
        />
          <PrivateRoutes
          exact
          path='/email/designer'
          component={EmailDesigner}
        />

        <Route exact path="/public/:id" component={PublicPage} />
      </Switch>
    </Router>
  </Provider>
);

export default App;
