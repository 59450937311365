import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import axios from '../../../../utils/axios';
import useNotificationDispatcher from '../../../../hooks/useNotificationDispatcher';

const FormsList = (props) => {
  const { setFilteItem, close } = props;

  const [formsList, setFormsList] = useState([]);
  const [dataReceive, setDataReceive] = useState(false);
  //   const formCategoryList = [
  //     { label: 'General Lead', value: 1 },
  //     { label: 'Social Lead', value: 2 },
  //   ];

  const notificationDispatch = useNotificationDispatcher();

  const onItemClick = (item) => {
    setFilteItem(item);
  };

  const getFormCategoryList = async () => {
    try {
      const res = await axios.get('/selectors/forms');
      if (res.data.length) {
        setFormsList(res.data);
        setDataReceive(true);
      } else {
        notificationDispatch('info', 'There are no forms assigned for you!');
      }
    } catch (error) {
      console.log(error);
      notificationDispatch('error', 'Something went wrong!');
    }
  };

  useEffect(() => {
    getFormCategoryList();
  }, []);

  return (
    <>
      {
        dataReceive && (
          <>
            <div
              onClick={close}
              className="backdrop global-modal-closer" />
            <div className="drop-down card" style={{ zIndex: 20 }}>
              <ul>
                {formsList.map(
                  (item, i) => (
                    <li
                      key={i}
                      onClick={() => onItemClick(item)}>
                      <>
                        {item.label}
                      </>
                    </li>
                  )
                )}
              </ul>
            </div>
          </>
        )
      }
    </>
  );
};

FormsList.propTypes = {
  setFilteItem: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default FormsList;
