const formatDate = (dateString) => {
  let formatedDate;
  if (dateString === null) {
    formatedDate = '';
  } else {
    const parsedDate = new Date(dateString);

    const dd = parsedDate.getDate();
    const mm = parsedDate.getMonth() + 1;
    const yyyy = parsedDate.getFullYear();
    const hr = parsedDate.getHours();
    const min = parsedDate.getMinutes();

    const mon = (mm < 10) ? `0${mm}` : mm;
    const day = (dd < 10) ? `0${dd}` : dd;
    const hour = (hr < 10) ? `0${hr}` : hr;
    const mnt = (min < 10) ? `0${min}` : min;
    
    formatedDate = `${yyyy}-${mon}-${day} ${hour}:${mnt}`;
  }
  return formatedDate;
};

export default formatDate;
