import { combineReducers } from 'redux';
import sidebar from './sidebarReducer';
import auth from './authReducers';
import notification from './notificationReducer';
import lead from './leadReducer';
import calendar from './calendarReducer';
import themeToggle from './themeToggleReducer';
import modalScroll from './modalScrollReducer';

export default combineReducers({
  sidebar,
  auth,
  notification,
  lead,
  calendar,
  themeToggle,
  modalScroll
});
