import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import axios from '../../../../utils/axios';
import useNotificationDispatcher from '../../../../hooks/useNotificationDispatcher';

const LeadStatusList = (props) => {
  const { setFilteItem } = props;
  const [leadStatusList, setLeadStatusList] = useState([]);
  const [dataReceive, setDataReceive] = useState(false);

  const notificationDispatch = useNotificationDispatcher();

  const getLeadStatusList = async () => {
    try {
      const res = await axios.get('/leads/lead-status');
      setLeadStatusList(res.data);
      setDataReceive(true);
      // console.log('status list', res.data);
    } catch (error) {
      console.log(error);
      notificationDispatch('error', 'Something went wrong!');
    }
  };

  const onItemClick = (item) => {
    setFilteItem(item);
  };

  useEffect(() => {
    getLeadStatusList();
  }, []);

  return (
    <>
      {
        dataReceive && (
          <div className="drop-down card">
            <ul>
              {leadStatusList.map(
                (item, i) => (
                  <li
                    key={i}
                    onClick={() => onItemClick(item)}>
                    {/* {console.log('item s', item)} */}
                    <>
                      {item.label}
                    </>
                  </li>
                )
              )}
            </ul>
          </div>
        )
      }
    </>
  );
};

LeadStatusList.propTypes = {
  setFilteItem: PropTypes.func.isRequired,
};

export default LeadStatusList;
