import { useDispatch } from 'react-redux';
import {
  SET_ERROR_MESSAGE,
  CLEAR_ERROR_MESSAGE,
  SET_SUCCESS_MESSAGE,
  CLEAR_SUCCESS_MESSAGE,
  SET_INFO_MESSAGE,
  CLEAR_INFO_MESSAGE,
} from '../actions/types';

const useNotificationDispatcher = () => {
  const dispatch = useDispatch();

  return (type, payload) => {
    switch (type) {
      case 'success':
        dispatch({
          type: SET_SUCCESS_MESSAGE,
          payload,
        });

        setTimeout(() => {
          dispatch({
            type: CLEAR_SUCCESS_MESSAGE,
          });
        }, 3000);
        break;
      case 'error':
        // console.log('errorlolz');
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload,
        });

        setTimeout(() => {
          dispatch({
            type: CLEAR_ERROR_MESSAGE,
          });
        }, 3000);
        break;
      case 'info':
        dispatch({
          type: SET_INFO_MESSAGE,
          payload,
        });

        setTimeout(() => {
          dispatch({
            type: CLEAR_INFO_MESSAGE,
          });
        }, 3000);
        break;
      default:
        break;
    }
    // if (payload.product) {
    //   dispatch({ type: ADD_ORDER_PRODUCT, payload });
    // } else {
    //   dispatch({ type: UPDATE_ORDER_DATA, payload });
    // }
  };
};

export default useNotificationDispatcher;
