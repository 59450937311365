/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import searchIcon from '../../../../assets/icons/search.svg';
// import callIcon from '../../../../assets/icons/call-btn.svg';
// import meetingIcon from '../../../../assets/icons/meeting-btn.svg';
// import emailIcon from '../../../../assets/icons/email-btn.svg';
import TableSearch from './TopActions/TableSearch';
import Filters from './TopActions/Filters';
import Columns from './TopActions/Columns';
import Delete from './TopActions/Delete';

const ActionBar = (props) => {
  const {
    checked, available, activateSearch, checkedCols, setCheckedColumns, 
    searchInput, setSearchInput, selectedRows, category, setLeads, 
    activateSelection, filterBy, setFilterBy, filtersList, viewAsInfo, 
    activateFilters, activateColumns, tableHeaders
  } = props;
  const [selected, setSelected] = useState(false);
  
 useEffect(() => {
    if (selectedRows.length > 0) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedRows]);
  return (
    <div className="action-bar-in">

      <div className="action-bar-left" />
      
      <div style={{ display: 'grid', gridTemplateColumns: '4fr 8fr', columnGap: '30px' }}>
        <div>
          {
          (!selected && activateSearch)
            ? (
              <TableSearch searchInput={searchInput} setSearchInput={setSearchInput} />
            ) : ''
          } 
        </div>
        <div className="action-bar-right">
         
          {
            (activateFilters) ? (
              <Filters filterBy={filterBy} setFilterBy={setFilterBy} viewAsInfo={viewAsInfo} filtersList={filtersList} />
              
            ) : ''
            
          }
          
          {
              (activateColumns) ? (
                <Columns checkedCols={checkedCols} setCheckedColumns={setCheckedColumns} />
              ) : ''
            }
         
        </div>
      </div>
    
      {
          (selected) ? (
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 6fr 2fr 18fr', columnGap: '20px' }}>
              <div>
                <label className="selected-label">{checked}/{available}</label>
              </div>
     
              {/* <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '10px' }}>
          <div className="interaction-btns">
            <img src={callIcon} alt="call" />
          </div>
          <div className="interaction-btns">
            <img src={meetingIcon} alt="meeting" />
          </div>
          <div className="interaction-btns">
            <img src={emailIcon} alt="email" />
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr 3fr', columnGap: '10px' }}>
          <div className="btn-wrapper">
            <button type="button" className="schedule-btns">Schedule</button>
          </div>
          <div className="btn-wrapper">
            <button type="button" className="schedule-btns">Set Reminder</button>
          </div>
          <div className="btn-wrapper" style={{ display: 'flex', justifyContent: 'left' }}>
            <button type="button" className="schedule-btns">Add a Note</button>
          </div>
        </div> */}
              <div style={{ display: 'grid', gridTemplateColumns: '2fr 2fr', columnGap: '10px' }}>
                {/* <div className="btn-wrapper">
            <button type="button" className="schedule-btns">Edit</button>
          </div> */}
                <Delete setLeads={setLeads} selectedRows={selectedRows} category={category} />
              </div>
            </div>
       ) : ''
      }

    </div>
  );
};

ActionBar.propTypes = {
  checked: PropTypes.number.isRequired,
  available: PropTypes.number.isRequired,
  tableHeaders: PropTypes.array,
  checkedCols: PropTypes.array.isRequired,
  setCheckedColumns: PropTypes.func.isRequired,
  searchInput: PropTypes.string.isRequired,
  setSearchInput: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  category: PropTypes.string,
  setLeads: PropTypes.func,
  filterBy: PropTypes.string,
  setFilterBy: PropTypes.func,
  viewAsInfo: PropTypes.object,
  filtersList: PropTypes.array,
  activateSearch: PropTypes.bool,
  activateSelection: PropTypes.bool,
  activateFilters: PropTypes.bool,
  activateColumns: PropTypes.bool,
};
export default ActionBar;
