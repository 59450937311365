import React from 'react';
import PropTypes from 'prop-types';

const LabeledInput = (props) => {
  const {
    id,
    type,
    label,
    placeholder,
    message,
    onChange,
    onFocus,
    value,
    required,
    isDisabled,
    maxValue,
    minValue,
    errorMessage,
  } = props;

  const _onChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="form-group">
      {label && <label htmlFor={id}>{label}</label>}
      <input
        value={value}
        type={type}
        onFocus={onFocus}
        className="form-controlx"
        id={id}
        aria-describedby={label}
        placeholder={placeholder}
        onChange={_onChange}
        disabled={isDisabled}
        required={required}
        max={maxValue}
        min={minValue}
      />
      <small
        id={id}
        className="form-text text-muted"
      >
        {message}
      </small>
      {
        errorMessage && (
          <small
            className="form-text text-warning"
          >
            * {errorMessage}
          </small>
        )
      }
    </div>
  );
};

LabeledInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  placeholder: PropTypes.string,
  message: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  isDisabled: PropTypes.bool,
  required: PropTypes.bool,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  errorMessage: PropTypes.string,
};

LabeledInput.defaultProps = {
  message: null,
  type: 'text',
  label: '',
  placeholder: '',
  required: false,
  errorMessage: '',
};

export default React.memo(LabeledInput);
