import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import SingleFileView from './SingleFileView';
import Branding from '../../layouts/Sidebar/Branding';
import axios from '../../utils/axios';
import Modal from '../../components/Common/Modal';
import LabeledInput from '../../components/InputFields/LabeledInput';
import { useDispatch } from 'react-redux';
import { SET_CURRENT_USER } from '../../actions/types';
import setAuthToken from '../../utils/setAuthToken';

const PublicPage = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const currentToken = localStorage.jwtToken;
    const [errorText, setErrorText] = useState('');
    const [typeId, setTypeId] = useState(0);
    const [resourceId, setResourceId] = useState(0);
    const [fileId, setFileId] = useState(0);
    const [isProtected, setIsProtected] = useState();
    const [isAuthorized, setIsAuthorized] = useState()
    const [passwordModal, setPasswordModal] = useState();
    const [passwordInput, setPasswordInput] = useState('');
    

    const closePasswordModal = () => {
        setPasswordModal(false);
    };

    const openPasswordModal = () => {
        setPasswordModal(true);
    };

    const getResourceData = async () => {
        openPasswordModal();
        const res = await axios.get(`/public-link/${id}`);
        const { data } = res;

        setTypeId(data.typeId);
        setResourceId(data.id);
        setFileId(data.resourceId);

        if (data.isProtected){
            setPasswordModal(true);
            setIsAuthorized(false);
            setIsProtected(true);
        } else {
            await submitPassword();           
        }
      }
  
      useEffect(() => {
        getResourceData();
      }, [id]);

    const handleToken = async (token) => {
        setAuthToken(token);
        localStorage.setItem('jwtToken', token);
        dispatch({ type: SET_CURRENT_USER, payload: token });
    }

    const submitPassword = async () => {
        const body = {
            id: resourceId,
            userPassword: passwordInput,
            isProtected,
        }
        try {
            const res = await axios.post('/public-link/auth', body);
            const { token } = res.data;
            if(!currentToken) {
                await handleToken(token);
            }
            setPasswordModal(false);
            setIsAuthorized(true);
           
        } catch (error) {
            console.log(error);
            setErrorText('Invalid request');
        }
    }

    return (
        <div className="public-page">
             <Branding />
             {isAuthorized && 
                <SingleFileView fileId={fileId} />
             }

        {
            passwordModal
            && (
            <Modal>
                <div className="">
                <div className="add-new-title">
                <h1>Password</h1>
                </div>
                <>
                
                    <div className="">
                    <LabeledInput
                        id="password"
                        type="password"
                        onChange={setPasswordInput}
                        value={passwordInput}
                    />
                    <div>
                    <div className="error" >{errorText}</div>
                    <button type="submit" className="btn btn-crm-maroon float-right ml-3" onClick={submitPassword}>View</button>
                    </div>
                </div>
                </>
            </div>
            </Modal>
            )
        }
        </div>
    )
}

PublicPage.propTypes = {

}

export default PublicPage
