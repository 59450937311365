import jwt from 'jsonwebtoken';

import isEmpty from '../validation/isEmpty';

import { SET_CURRENT_USER, CLEAR_CURRENT_USER } from '../actions/types';
import setAuthToken from '../utils/setAuthToken';

const initialState = {
  isAuthenticated: false,
  user: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER: {
      setAuthToken(action.payload);
      const decoded = jwt.decode(action.payload);
      const {
        user, defaultSettings, documentsSettings, client 
      } = decoded;
      return {
        isAuthenticated: !isEmpty(user),
        user,
        defaultSettings,
        documentsSettings,
        client
      };
    }
    case CLEAR_CURRENT_USER: {
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('cols');
      setAuthToken(null);
      return {
        isAuthenticated: false,
        user: {},
        defaultSettings: {},
        documentsSettings: {},
        client: {}
      };
    }
    default:
      return state;
  }
};
