import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { isEmail, isMobilePhone, isPhoneNumber } from 'class-validator';
import axios from '../../../../utils/axios';
import useNotificationDispatcher from '../../../../hooks/useNotificationDispatcher';
import editIcon from '../../../../assets/icons/pencil.svg';

const TextDataUpdate = (props) => {
  const {
    rowData, category, setLeads, value, columnId, clickedRow, id
  } = props;
  const initialValue = value;
  const notificationDispatch = useNotificationDispatcher();

  const updateInput = useRef(null);
  const [newValue, setNewValue] = useState(value);
  const [edit, setEdit] = useState(false);
  const [enableTextEdit, setEnableTextEdit] = useState(false);

  const updateLeadData = async (item) => {
    const updateData = {
      leadId: rowData.id, updatedValue: item, currentSelectedCategory: category.value, fieldToUpdate: columnId, fieldToUpdateId: id
    };
    if (columnId === 'leadTitle') {
      try {
        const updateRes = await axios.put('/leads/update-title', updateData);
        setLeads(updateRes.data.leads);
        notificationDispatch('success', 'Lead Updated Successfully !');
      } catch (error) {
        console.log(error);
        notificationDispatch('error', 'Something went wrong!');
      }
    } else if (columnId === 'email') {
      if (isEmail(item)) {
        try {
          const updateRes = await axios.put('/leads/update-email', updateData);
          setLeads(updateRes.data.leads);
          notificationDispatch('success', 'Lead Updated Successfully !');
        } catch (error) {
          console.log(error);
          notificationDispatch('error', 'Something went wrong!');
        }
      } else {
        notificationDispatch('error', 'Please enter a valid Email!');
        setNewValue(value);
      }
    } else if (columnId === 'contactNumber') {
      if (isPhoneNumber(item) || isMobilePhone(item)) {
        try {
          const updateRes = await axios.put('/leads/update-contactNumber', updateData);
          setLeads(updateRes.data.leads);
          notificationDispatch('success', 'Lead Updated Successfully !');
        } catch (error) {
          console.log(error);
          notificationDispatch('error', 'Something went wrong!');
        }
      } else {
        notificationDispatch('error', 'Enter a Valid Contact Number!');
        setNewValue(value);
      }
    } else if (columnId === 'city') {
      try {
        const updateRes = await axios.put('/leads/update-city', updateData);
        setLeads(updateRes.data.leads);
        notificationDispatch('success', 'Lead Updated Successfully !');
      } catch (error) {
        console.log(error);
        notificationDispatch('error', 'Something went wrong!');
      }
    } else {
      try {
        const updateRes = await axios.put('/leads/update-optional-columns', updateData);
        setLeads(updateRes.data.leads);
        notificationDispatch('success', 'Lead Updated Successfully!');
      } catch (error) {
        console.log(error);
        notificationDispatch('error', 'Something went wrong!');
      }
    }
  };
  const onChange = (e) => {
    setEdit(false);
    setNewValue(e.target.value);
  };

  const onBlur = () => {
    console.log('enable in blur', enableTextEdit);
    setEdit(false);
    if (initialValue !== newValue && rowData.id === clickedRow) {
      updateLeadData(newValue);
    }
    setEnableTextEdit(false);
  };

  const showEdit = () => {
    setEdit(true);
  };
  const hideEdit = () => {
    setEdit(false);
  };

  const onEditClick = () => {
    setEnableTextEdit(true);
    updateInput.current.focus();
    setEdit(false);
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '5fr 1fr', columnGap: '5px' }}>
      <div onMouseEnter={showEdit} onMouseLeave={hideEdit}>
        <input
          ref={updateInput}
          value={newValue}
          disabled={!enableTextEdit}
          className={enableTextEdit ? 'editable-texts' : 'normal-texts'}
          onBlur={onBlur}
          onChange={onChange}
          rowData={rowData}
          category={category}
        />
      </div>
      <div onMouseEnter={showEdit} onMouseLeave={hideEdit} onMouseOver={showEdit} onFocus={showEdit} onBlur={hideEdit} onClick={onEditClick}>
        {edit
          ? <img src={editIcon} alt="" style={{ cursor: 'pointer' }} /> : ''}
      </div>

    </div>

  );
};

TextDataUpdate.propTypes = {
  value: PropTypes.string.isRequired,
  rowData: PropTypes.object.isRequired,
  columnId: PropTypes.string.isRequired,
  category: PropTypes.object.isRequired,
  setLeads: PropTypes.func.isRequired,
  clickedRow: PropTypes.number.isRequired,
  id: PropTypes.number,
};

export default TextDataUpdate;
