import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import countryList from 'react-select-country-list';
import SearchSelect from '../InputFields/SearchSelect';

const CountrySelect = (props) => {
  const {
    onChange, label, link, linkPopup, useEventValue, defaultValue, errorMessage
  } = props;

  const countries = useMemo(() => countryList().getData(), []);

  const _onChange = (val) => {
    onChange(val);
  };

  return (
    <div>
      <SearchSelect
        label={label}
        placeholder="Select a country..."
        name="CountrySelect"
        options={countries}
        onChange={_onChange}
        link={link}
        linkPopup={linkPopup}
        useEventValue={useEventValue}
        value={defaultValue}
      />
      {
        errorMessage && (
          <small
            className="form-text text-warning"
          >
            * {errorMessage}
          </small>
        )
      }
    </div>
  );
};

CountrySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkPopup: PropTypes.func,
  useEventValue: PropTypes.bool,
  defaultValue: PropTypes.object,
  errorMessage: PropTypes.string,
};

CountrySelect.defaultProps = {
  useEventValue: true,
  errorMessage: '',
};

export default CountrySelect;
