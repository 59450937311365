import React from 'react';

import User from './User';
// import Notification from './Notification';
import Help from './Help';
import Branding from '../Sidebar/Branding';
import Navigation from './Navigation';
import SearchBar from './SearchBar';

const Topbar = () => (
  <header>
    <Branding />
    <div className="row nav-container">
      <div className="col-8">
        <Navigation />
      </div>
      <div
        className="col-4 search-bar-container">
        <SearchBar />
      </div>
    </div>
    <div className="util-icons d-flex mr-auto settings-container">
      <Help />
      {/* <Notification /> */}
      <User />
    </div>
    {/* </div> */}
  </header>
);

export default React.memo(Topbar);
