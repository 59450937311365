import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { darkThemeOff, scrollDown, scrollHide } from '../../../actions/types';
// import dropicon from '../../../assets/icons/nav/latest/left-arrow(1).svg';

const getSidebarStatus = createSelector(
  (state) => state.sidebar,
  (sidebar) => sidebar.isOpen,
);

const NavItem = (props) => {
  const isOpen = useSelector(getSidebarStatus);
  const {
    icon,
    link,
    label,
    children,
    disabledFor,
    groupId,
    selectedNav,
    setSelectedNav,
    addStyle,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const _onMouseEnter = () => {
    if (children) {
      setSelectedNav(label);
    }
  };

  const _onMouseLeave = () => {
    setSelectedNav('');
  };

  const _onClick = () => {
    setSelectedNav('');
    history.push(link);
  };

  if (disabledFor.includes(groupId)) {
    return <></>;
  }

  const handleTheme = () => {
    dispatch(darkThemeOff());
    dispatch(scrollHide());
  };

  return (
    <li className={`nav-item ${children ? 'has-sub' : ''} ${addStyle || ''}`} onClick={handleTheme}>
      {
        children
          ? (
            <div onMouseEnter={_onMouseEnter} className="nav-link">
              <img src={icon} alt="" />
              {isOpen && <span>{label}</span>}
            </div>
          )
          : (
            <div onMouseEnter={_onMouseLeave} onClick={_onClick} className="nav-link">
              <img src={icon} alt="" />
              {isOpen && <span>{label}</span>}
            </div>
          )
      }
      {
        (selectedNav === label)
        && (
          <ul className="top-nav-drop-down nav-sub-modal">
            {children}
          </ul>
        )
      }
    </li>
  );
};

NavItem.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  children: PropTypes.node,
  disabledFor: PropTypes.array,
  groupId: PropTypes.number,
  selectedNav: PropTypes.string,
  setSelectedNav: PropTypes.func,
  addStyle: PropTypes.string,
};

NavItem.defaultProps = {
  children: null,
  disabledFor: [],
};

export default NavItem;
