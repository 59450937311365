import React, { useEffect, useState } from 'react'
import axios from '../../../utils/axios';
import DocumentPreview from './DocumentPreview';
import RightPane from './RightPane';

const SingleFileView = (props) => {
    const { fileId } = props;
    const [docs, setDocs] = useState([]);
    const [fileData, setFileData] = useState({});
    const [comments, setComments] = useState([]);
    const [history, setHistory] = useState([]);
    const [activities, setActivities] = useState([]);
    const [dataUpdates, setDataUpdates] = useState(1);
    console.log({history, activities});
    const getFileData = async () => {
      const res = await axios.get(`/documents/${fileId}`);
      setDocs([{uri: res.data?.salesDealFiles[0]?.fileUrl}]);
      setFileData(res.data?.salesDealFiles[0]);
      setComments(res.data?.comments);
      setHistory(res.data?.documentHistory);
      setActivities(res.data?.documentActivities);
    }

    useEffect(() => {
      getFileData();
    }, [fileId]);
    
    return (
        <div className="single-file-view-public">
           <div className="left-pane">
             <DocumentPreview 
             docs={docs}
             />
          </div>
          <div className="right-pane">
              <RightPane 
              fileData={fileData}
              setDocs={setDocs}
              setFileData={setFileData}
              comments={comments}
              setComments={setComments}
              fileId={fileId}
              history={history}
              setHistory={setHistory}
              activities={activities}
              setActivities={setActivities}
              dataUpdates = {dataUpdates}
              setDataUpdates = {setDataUpdates}
              />
          </div>
        </div>
    )
}

export default SingleFileView
