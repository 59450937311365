import { OPEN_SIDEBAR, CLOSE_SIDEBAR } from '../actions/types';

const initialState = {
  isOpen: true,
  width: 230,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return {
        ...state,
        isOpen: true,
        width: 230,
      };
    case CLOSE_SIDEBAR:
      return {
        ...state,
        isOpen: false,
        width: 60,
      };
    default:
      return state;
  }
};
