import React from 'react';
import PropTypes from 'prop-types';

const DropDownList = (props) => {
  const { suggestions, suggestionSelected } = props;

  return (
    <div className="auto-complete">
      <div className="drop-down card">
        <ul>
          {suggestions.map(
            (item) => (
              <li
                onClick={() => suggestionSelected(item)}>
                <>
                  {item.name}
                  <label className="float-right mr-2">{item.type}</label>
                </>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

DropDownList.propTypes = {
  suggestions: PropTypes.array,
  suggestionSelected: PropTypes.func,
};

export default DropDownList;
