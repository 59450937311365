import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import axios from '../../../../utils/axios';
import useNotificationDispatcher from '../../../../hooks/useNotificationDispatcher';

import LeadAgentsList from '../DropDowns/LeadAgentsList';
import editIcon from '../../../../assets/icons/pencil.svg';

const AssignedAgent = (props) => {
  const {
    rowData, clickedRow, setLeads, category
  } = props;
  const updateAgentInput = useRef(null);
  const [agentUpdateDrop, setAgentUpdateDrop] = useState(false);
  const [edit, setEdit] = useState(false);
  const [enableTextEdit, setEnableTextEdit] = useState(false);
  const notificationDispatch = useNotificationDispatcher();

  const _onAgentUpdateClick = () => {
    if (rowData.id === clickedRow) {
      setAgentUpdateDrop(!agentUpdateDrop);
    }
  };

  const updateAssignedAgent = async (item) => {
    try {
      const updateData = { leadId: rowData.id, newAgentId: item.value, currentSelectedCategory: category.value };
      console.log(updateData);
      const updateRes = await axios.put('/leads/update-agent', updateData);
      setLeads(updateRes.data.leads);
      console.log(updateRes.data.leads);
      setAgentUpdateDrop(false);
      notificationDispatch('success', 'Lead Updated Successfully!');
    } catch (error) {
      console.log(error);
      notificationDispatch('error', 'Something went wrong!');
    }
  };

const _setFilterItem = (item) => {
    updateAssignedAgent(item);
  };
const showEdit = () => {
    setEdit(true);
};
const hideEdit = () => {
    setEdit(false);
};

const onEditClick = () => {
    setEnableTextEdit(true);
    updateAgentInput.current.focus();
    _onAgentUpdateClick();
    setEdit(false);
};

  return (
    <div className="actions cell-dropdown" style={{ flex: 1 }}>
      <div className="cell-dropdown-align cell-container">
        <div style={{ display: 'grid', gridTemplateColumns: '5fr 1fr', columnGap: '5px' }}>
          <div onMouseEnter={showEdit} onMouseLeave={hideEdit}>
            <input
              value={rowData.assignedAgent}
              ref={updateAgentInput}
              disabled
              className={enableTextEdit ? 'editable-texts' : 'normal-texts'}
              style={{ cursor: 'context-menu' }} 
                />
          </div>
          <div onMouseEnter={showEdit} onMouseLeave={hideEdit} onMouseOver={showEdit} onFocus={showEdit} onBlur={hideEdit} onClick={onEditClick}> 
            {edit 
            ? <img src={editIcon} alt="" /> : ''}
          </div>
        </div>
      </div>
      {
        agentUpdateDrop && (
          <LeadAgentsList setFilteItem={_setFilterItem} />
        )
      }
    </div>
  );
};

AssignedAgent.propTypes = {
  setLeads: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  clickedRow: PropTypes.number.isRequired,
  category: PropTypes.object.isRequired,
};

export default AssignedAgent;
