import React from 'react';
import Body from '../../layouts/Body';

const Loader = () => (
  <Body>
    <div className="loader-container">
      <div className="lds-facebook">
        <div />
        <div />
        <div />
      </div>
    </div>
  </Body>
);

export default Loader;
