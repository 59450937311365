import React from 'react'
import PropTypes from 'prop-types'
import parseDate from '../../../../utils/parseDateTime';

const FileHistory = (props) => {
    const { history } = props;
    return (
        <div className="file-history-item">
            <div className="file-name">{history.fileName}</div>
            <div className="file-type">{history.fileType}</div>
            <div className="file-size">{history.fileSize}</div>
            <div className="file-date">{parseDate(history.fileUpdatedDate)}</div>
            <div className="file-view"><a href={history.fileUrl} target="_blank">View</a></div>
        </div>
    )
}

FileHistory.propTypes = {
    history: PropTypes.array.isRequired,
}

export default FileHistory
