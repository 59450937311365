import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import Modal from '../../../../components/Common/Modal';
import useNotificationDispatcher from '../../../../hooks/useNotificationDispatcher';

import LabeledInput from '../../../../components/InputFields/LabeledInput';
import QuestionTypeSelect from '../../../../components/SelectFields/QuestionTypeSelect';
import SelectTypes from './SelectField';

const AddNewQuestion = (props) => {
  const {
    setQuestions, questions
  } = props;

  const [title, setTitle] = useState(null);
  const [placeholder, SetPlaceholder] = useState('');
  const [isContactNo, setIsContactNo] = useState(false);
  const [isCountry, setIsCountry] = useState(false);
  const [isGender, setIsGender] = useState(false);
  const [minRange, setMinRange] = useState(null);
  const [maxRange, setMaxRange] = useState(null);
  const [optionModal, setOptionModal] = useState(false);
  const [quType, setQuType] = useState('');
  const [addOptionExpand, setAddOptionExpand] = useState(false);
  const [questionOptions, setQuestionOptions] = useState([]);

  const notificationDispatch = useNotificationDispatcher();

  const onAddQuestion = () => {
    if (!title || title === '') {
      notificationDispatch('error', 'Please Enter A Question Title');
      return;
    }
    if (!quType || quType === '') {
      notificationDispatch('error', 'Please Enter A Valid Question Type');
      return;
    }

    if (!isCountry && questionOptions.length === 0 && quType.value !== 'text' && quType.value !== 'number') {
      notificationDispatch('error', 'Please Enter A question option');
      return;
    }

    if (!isGender && questionOptions.length === 0 && quType.value !== 'text' && quType.value !== 'number') {
      notificationDispatch('error', 'Please Enter A question option');
      return;
    }

    const copyOfQuestionsArr = [...questions];
    const questionObj = {
    title, questionCompulsory: false, quType, placeholder, minRange, maxRange, isContactNo, isCountry, isGender, options: questionOptions
    };
    copyOfQuestionsArr.push({
      ...questionObj
    });

    setQuestions(copyOfQuestionsArr);

    setTitle('');
    setQuType(null);
    SetPlaceholder('');
    setMinRange('');
    setMaxRange('');
    setIsContactNo(false);
    setIsCountry(false);
    setIsGender(false);
    setQuestionOptions([]);
  };

  const selectOptionSection = (typ) => {
    setQuType(typ);
    if (typ.value !== 'text' && typ.value !== 'number') {
      setAddOptionExpand(!addOptionExpand);
    }
  };

  const openOptionModal = () => {
    setOptionModal(true);
  };

  const closeOptionModal = () => {
    setOptionModal(false);
  };

  const onIsContactNoClick = (e) => {
    setIsContactNo(e.target.checked);
  };

  const onIsCountryClick = (e) => {
    setIsCountry(e.target.checked);
  };

  const onIsGenderClick = (e) => {
    setIsGender(e.target.checked);
  };

  useEffect(() => {
    setIsContactNo(isContactNo);
    setIsCountry(isCountry);
    setIsGender(isGender);
  }, []);

  return (
    <form className="card card-body">
      <div className="row mb-3">
        <div className="col-md-3 mt-5">
          <LabeledInput
            id="title"
            label="Title"
            placeholder="Enter Question..."
            onChange={setTitle}
            value={title}
          />
        </div>

        <div className="col-md-3 mt-5">
          <QuestionTypeSelect
            onSelectChange={selectOptionSection}
            value={quType}

          />
        </div>
        {
          addOptionExpand && (
            <>
              <div className="col-md-1 mt-5">
                <button type="button" className="btn-sm btn-outline-crm mt-7 add-option" onClick={openOptionModal}>Add Option</button>
              </div>
            </>
          )
        }

        {
          optionModal && (
            <Modal modalClose={closeOptionModal} addClass="NewForm">
              <SelectTypes
                setQuestionOptions={setQuestionOptions}
                questionOptions={questionOptions}
                closeModal={closeOptionModal}
              />
            </Modal>
          )
        }

        <div className="col-md-3 mt-5">
          <LabeledInput
            id="placeholder"
            label="Placeholder"
            onChange={SetPlaceholder}
            value={placeholder}
          />
        </div>

      </div>

      <div className="row mt-10">

        <div className="col-md-1">
          <LabeledInput
            id="minRange"
            label="Min Range"
            // placeholder="e.g. Form 1"
            onChange={setMinRange}
            value={minRange}
          />
        </div>
        <div className="col-md-1">
          <LabeledInput
            id="maxRange"
            label="Max Range"
            // placeholder="e.g. Form 1"
            onChange={setMaxRange}
            value={maxRange}
          />
        </div>

        <Checkbox
          checked={isContactNo}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          style={{ height: '35px', width: '35px' }}
          onChange={onIsContactNoClick}
        />
        <h1 className="mt-2">Is contact number? </h1>

        <Checkbox
          checked={isCountry}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          style={{ height: '35px', width: '35px' }}
          onChange={onIsCountryClick}
        />
        <h1 className="mt-2">Is Country? </h1>

        <Checkbox
          checked={isGender}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          style={{ height: '35px', width: '35px' }}
          onChange={onIsGenderClick}
        />
        <h1 className="mt-2">Is Gender? </h1>

      </div>
      <button type="button" className="btn btn-crm-maroon btn-save float-right ml-3" onClick={onAddQuestion}>Add </button>

      <div className="col-md-12" />
    </form>
  );
};

AddNewQuestion.propTypes = {
  setQuestions: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,

};

export default AddNewQuestion;
