import React from 'react';
import { useSelector } from 'react-redux';

import FullLogo from '../../../assets/logos/salessuite-logo.png';
import darkThemeLogo from '../../../assets/logos/salessuite-logo-dark.png';

const Branding = () => {
  const darkTheme = useSelector((state) => state.themeToggle.dark);

  return (
    <div className="branding">
      <img src={darkTheme === 'OFF' ? FullLogo : darkThemeLogo} alt="Company Logo" />
    </div>
);
};
export default Branding;
