// import { TOGGLE_DARK_THEME } from '../actions/types';

const initialState = {
  dark: 'OFF'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'DARK_THEME':
      return { ...state, dark: 'ON' };
    case 'DARK_THEME_OFF':
      return { ...state, dark: 'OFF' };
    default:
      return state;
  }
};
