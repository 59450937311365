import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import makeAnimated from 'react-select/animated';
import MenuList from '../Lists/MenuList';

const SearchSelect = memo((props) => {
  const {
    options,
    controlled,
    placeholder,
    onChange,
    name,
    label,
    link,
    linkPopup,
    disabled,
    isMulti,
    openMenuOnSelect,
    value,
    useEventValue,
    maxMenuHight,
    listType,
    showLinkToPopup,
    defaultValue,
  } = props;

  const _onChange = (e) => {
    if (useEventValue) {
      onChange(e.value);
    } else {
      onChange(e);
    }
  };

  return (
    <>
      {
        label && (
          <label htmlFor="search-select" className="form-label">
            {label}
            {
              showLinkToPopup && (
                <>
                  <span className="form-label-link" onClick={linkPopup} htmlFor="search-select">{link}</span>
                </>
              )
            }
          </label>
        )
      }
      {
        // eslint-disable-next-line no-nested-ternary
        listType === 'short' ? (
          (value || controlled) ? (
            <Select
              options={options}
              placeholder={placeholder}
              onChange={_onChange}
              name={name}
              isMulti={isMulti}
              components={makeAnimated()}
              closeMenuOnSelect={!openMenuOnSelect}
              id="search-select"
              isDisabled={disabled}
              value={value}
              className="react-select-container"
              classNamePrefix="react-select"
              maxMenuHeight={maxMenuHight}
              defaultValue={defaultValue}
            // clickedValue = {clickedValue}
            />
          ) : (
            <Select
              options={options}
              placeholder={placeholder}
              onChange={_onChange}
              name={name}
              isMulti={isMulti}
              components={makeAnimated()}
              closeMenuOnSelect={!openMenuOnSelect}
              id="search-select"
              isDisabled={disabled}
              className="react-select-container"
              classNamePrefix="react-select"
              maxMenuHeight={maxMenuHight}
              defaultValue={defaultValue}
            />
          )
        ) : (
          <Select
            filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
            components={{ MenuList }}
            options={options}
            placeholder={placeholder}
            onChange={_onChange}
            id="search-select"
            name={name}
            label={value}
            isDisabled={disabled}
          />
        )
      }
    </>
  );
});

SearchSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  link: PropTypes.string,
  linkPopup: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  openMenuOnSelect: PropTypes.bool,
  value: PropTypes.object,
  maxMenuHight: PropTypes.string,
  useEventValue: PropTypes.bool,
  controlled: PropTypes.bool,
  listType: PropTypes.string,
  showLinkToPopup: PropTypes.bool,
  defaultValue: PropTypes.object,
};

SearchSelect.defaultProps = {
  label: null,
  link: null,
  placeholder: null,
  disabled: false,
  isMulti: false,
  openMenuOnSelect: false,
  controlled: false,
  value: null,
  listType: 'short',
};

export default SearchSelect;
