/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import columnIcon from '../../../../assets/icons/columns.svg';

const Columns = (props) => {
    const { checkedCols, setCheckedColumns } = props;
    const [columnsDropDown, setColumnsDropDown] = useState(false);

    const _onClick = () => {
        setColumnsDropDown(!columnsDropDown);
      };
      const onItemClick = (item) => {
        const copyCheckedCols = [...checkedCols];
        setColumnsDropDown(true);
        copyCheckedCols.forEach((element) => {
          if (element.field_title === item.field_title) {
            if (element.checked === true) {
              element.checked = false;
            } else {
              element.checked = true;
            }
          }
        });
        setCheckedColumns([...copyCheckedCols]);
        localStorage.setItem('cols', JSON.stringify(copyCheckedCols));
      };
  return (
    <div onClick={_onClick} style={{ paddingLeft: '860px' }}>
      <label>Columns</label>
      <img src={columnIcon} alt="" />
      {
      columnsDropDown && (
        <div className="drop-down card">
          <ul>
            {checkedCols.map(
              (item, i) => (

                <li style={{ listStyle: 'none' }}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr', columnGap: '10px' }}>
                    <div>
                      <input
                        type="checkbox"
                        key={i}
                        onChange={() => onItemClick(item, i)}
                        checked={item.checked}
                      />
                    </div>
                    <div>
                      <label>{item.field_title}</label>
                    </div>
                  </div>
                </li>
              )
            )}
          </ul>
        </div>
      )
    }
    </div>
  );
};

Columns.propTypes = {
    checkedCols: PropTypes.array.isRequired,
    setCheckedColumns: PropTypes.func.isRequired,
};

export default Columns;
