const validateForm = (formInfo) => {
    let errorMessage = '';
    let errorStatus = false;
    const {
        formName, formDiscription, questions,
    } = formInfo;
  
    if (!formName || formName === '') {
      errorStatus = true;
      errorMessage = 'Please Enter a Form Name!';
      const error = { status: errorStatus, errorMessage };
      return error;
    }
  
    if (formDiscription === '') {
      errorStatus = true;
      errorMessage = 'Please Enter a Form Description!';
      const error = { status: errorStatus, errorMessage };
      return error;
    }

    if (questions.length === 0) {
      errorStatus = true;
      errorMessage = 'Please Add Questions!';
      const error = { status: errorStatus, errorMessage };
      return error;
    }

       const error = { status: errorStatus, errorMessage };
    return error;
  };
  
  export default validateForm;
