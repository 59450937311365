import React, {useState} from 'react'
import PropTypes from 'prop-types'
import UploadNewVersion from './UploadNewVersion';
import GeneratePublicLink from './GeneratePublicLink';
import axios from '../../../../utils/axios';
import useNotificationDispatcher from '../../../../hooks/useNotificationDispatcher';

const FileInfo = (props) => {
    const { fileData, setFileData, setDocs, fileId, setHistory, setActivities, dataUpdates, setDataUpdates } = props;
    const { fileName, description, fileSize, fileType, customerApproval, salesDealId, publicLink } = fileData;
    const [uploadNewVersionSection, setUploadNewVersionSection] = useState(false);
    const [generatePublicLinkSection, setGeneratePublicLinkSection] = useState(false);
    const [approve, setApprove] = useState('Approve');
    const [toolTipText, setToolTipText] = useState(null);
    const UploadNewVersionSectionOpen= () => {
        setUploadNewVersionSection(true);
    };

    const UploadNewVersionSectionClose= () => {
        setUploadNewVersionSection(false);
    };

    const GeneratePublicLinkSectionOpen= () => {
        setGeneratePublicLinkSection(true);
    };

    const GeneratePublicLinkSectionClose= () => {
        setGeneratePublicLinkSection(false);
    };

    const CopyPublicLink = () => {
        navigator.clipboard.writeText(publicLink);
        setToolTipText(`Copied: ${publicLink}`);
        notificationDispatch('success', 'Link Copied to Clipboard!');
    }
    const notificationDispatch = useNotificationDispatcher();
    const data = {salesDealId};
    const Approve = async () => {
        const activities = await axios.post(`/documents/customer-approval/${fileId}`, data);
        setApprove('');
        setActivities(activities.data.documentActivities);
        setDataUpdates(dataUpdates + 1);
    };
    console.log({customerApproval});
    return (
        <>
        {!uploadNewVersionSection && !generatePublicLinkSection &&
         <div className="file-info">
         <div>File Name: {fileName}</div>
         <div>Description: {description}</div>
         <div>File Size: {fileSize}</div>
         <div>File Type: {fileType}</div>
         <div className="action-buttons">
           {/* <button className="btn btn-crm-maroon spacing" onClick={UploadNewVersionSectionOpen}>Upload New Version</button> */}
           {customerApproval === 2 && approve === 'Approve' && 
           <button className="btn btn-crm-maroon spacing" onClick={Approve}>{approve}</button>
           }
           {publicLink ? 
             <button className="btn btn-crm-maroon spacing" onClick={CopyPublicLink} data-toggle="tooltip" title={toolTipText}>Copy Public Link</button>
                 : 
             <button className="btn btn-crm-maroon spacing" onClick={GeneratePublicLinkSectionOpen}>Generate Public Link</button>
            }
            
         </div>
       </div>
        }
        {/* {uploadNewVersionSection &&
        <div className="upload-new">
             <UploadNewVersion fileId={fileId} setFileData={setFileData} setDocs={setDocs} close={UploadNewVersionSectionClose} setHistory={setHistory} setActivities={setActivities}/>
        </div>
        } */}
         {generatePublicLinkSection &&
        <div className="upload-new">
             <GeneratePublicLink fileId={fileId} setFileData={setFileData} close={GeneratePublicLinkSectionClose} setActivities={setActivities} salesDealId={salesDealId} dataUpdates={dataUpdates} setDataUpdates={setDataUpdates}/>
        </div>
        }

        </>
    )
}

FileInfo.propTypes = {
    fileData: PropTypes.object.isRequired,
    setFileData: PropTypes.func.isRequired,

}

export default FileInfo
