import {
  SELECT_CALENDAR_EVENT,
  CLEAR_CALENDAR_EVENT,
  ADD_TODAYS_EVENTS,
  CLEAR_TODAYS_EVENTS,
  ADD_SCHEDULED_EVENTS,
  CLEAR_SCHEDULED_EVENTS,
} from '../actions/types';

const initialState = {
  event: null,
  todaysEvents: [],
  scheduledEvents: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_CALENDAR_EVENT:
      return {
        ...state,
        event: action.payload,
      };
    case CLEAR_CALENDAR_EVENT:
      return {
        ...state,
        event: null,
      };
    case ADD_TODAYS_EVENTS:
      return {
        ...state,
        todaysEvents: action.payload,
      };
    case CLEAR_TODAYS_EVENTS:
      return {
        ...state,
        todaysEvents: [],
      };
    case ADD_SCHEDULED_EVENTS:
      return {
        ...state,
        scheduledEvents: action.payload,
      };
    case CLEAR_SCHEDULED_EVENTS:
      return {
        ...state,
        scheduledEvents: [],
      };
    default:
      return state;
  }
};
